function syncEnquiry(obj){
	obj.dateArrive = obj.$store.getters.getArrivalDate;
	obj.dateDeparture = obj.$store.getters.getDepartureDate;
	obj.adults = obj.$store.getters.getAdults;
	obj.kids = obj.$store.getters.getKids;
	obj.infants = obj.$store.getters.getInfants;
	obj.bedrooms = obj.$store.getters.getBedrooms;
	obj.kinderbed = obj.$store.getters.getKinderbed;
}


export default syncEnquiry;
