<template>
	<img :src="bamSmallImage" :alt="bamAlt" :data-src="bamFullImage" class="lozad">
	<!-- <img :alt="bamAlt" :src="bamFullImage"> -->
</template>
<script>
export default {
	props: {
		bamSmallImage: String,
		bamAlt: String,
		bamFullImage: String
	}
}
</script>

<style lang="scss" scoped>
	.lozad {
		filter: blur(15px);
		transition: filter .5s;
		&.loaded {
			filter: none;
		}
	}
</style>
