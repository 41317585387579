<template>
<div class="search__overlay">
	<div class="search__scroller">
		<div class="custom-container">

			<h2 class="search__header">Choose your villa or residence</h2>
			<div class="search__select search__select--custom">
				<div class="search__date-first">
					<button @click="datefirst = true">
						<div class="search__arrival">
							<span class="search__arrival-span search__arrival-span--hide-on-mobile">arr.</span>
							<span class="search__arrival-span">ARRIVAL</span>
						</div>
						<div class="search__date-first-wrapper">
							<div class="search__number">
								{{ String(dateArrive.getDate()).padStart(2, '0') }}
							</div>
							<div class="search__calendar">
								<div class="search__month">
									{{ monthNames[dateArrive.getMonth()] }}
								</div>
								<div class="search__year">
									{{ dateArrive.getFullYear() }}
								</div>
							</div>
							<img src="@/assets/img/arrow.svg"
							     alt="arrow">
						</div>
					</button>
					<transition name="show-modal">
						<div class="date-first-modal"
						     v-show="datefirst">
							<datepicker :disabled-dates="disabledDatesArrive"
							            :inline="true"
							            v-model="dateArrive"></datepicker>
							<div class="modal-bottom-custom">
								<button @click="arriveCommit"
								        class="btn">OK</button>
							</div>
						</div>
					</transition>
				</div>
				<div class="search__date-second">
					<button @click="datesecond = true">
						<div class="search__arrival">
							<span class="search__arrival-span search__arrival-span--hide-on-mobile">dep.</span>
							<span class="search__arrival-span">DEPARTURE</span>
						</div>
						<div class="search__date-second-wrapper">
							<div class="search__number">
								{{ String(dateDeparture.getDate()).padStart(2, '0') }}
							</div>
							<div class="search__calendar">
								<div class="search__month">
									{{ monthNames[dateDeparture.getMonth()] }}
								</div>
								<div class="search__year">
									{{ dateDeparture.getFullYear() }}
								</div>
							</div>
							<img src="@/assets/img/arrow.svg"
							     alt="arrow">
						</div>
					</button>
					<transition name="show-modal">

						<div class="date-second-modal"
						     v-if="datesecond">
							<datepicker :disabled-dates="disabledDates"
							            :inline="true"
							            v-model="dateDeparture"></datepicker>
							<div class="modal-bottom-custom">
								<button @click="departureCommit"
								        class="btn">OK</button>
							</div>
						</div>
					</transition>
				</div>
				<div class="search__guests">
					<button @click="guests = true">
						<div class="search__arrival">
							guests
						</div>
						<div class="search__guest-wrapper">

							<div class="search__number search__number--margin">
								{{ adults + kids | formatNumber }}
							</div>
							<img src="@/assets/img/arrow.svg"
							     alt="arrow">
						</div>
					</button>
					<transition name="show-modal">

						<div class="guests-modal"
						     v-if="guests">
							<div class="modal-topbar">
								<p>Guests</p>
								<button @click="guests = false"><img src="@/assets/img/small_close.svg"
									     alt="Close"></button>
							</div>
							<div class="modal-calc">
								<div class="modal-section">
									<p>Adults</p>
									<div class="modal-buttons">
										<button @click="adultsMinus"
										        :class='{"grayColor" : adults == 1}'>-</button>
										<span class="search__number"
										      :class='{"grayColor" : adults == 0}'>{{ adults | formatNumber }}</span>
										<button @click="adultsPlus">+</button>
									</div>
								</div>
								<div class="modal-section">
									<p>Kids (2-12)</p>
									<div class="modal-buttons">
										<button @click="kidsMinus"
										        :class='{"grayColor" : kids == 0}'>-</button>
										<span class="search__number"
										      :class='{"grayColor" : kids == 0}'>{{ kids | formatNumber }}</span>
										<button @click="kidsPlus">+</button>
									</div>
								</div>
								<div class="modal-section">
									<p>Infants (0-2)</p>
									<div class="modal-buttons">
										<button @click="infantsMinus"
										        :class='{"grayColor" : infants == 0}'>-</button>
										<span class="search__number"
										      :class='{"grayColor" : infants == 0}'>{{ infants | formatNumber }}</span>
										<button @click="infantsPlus">+</button>
									</div>
								</div>
								<p class='modal-info-text'>Infants don't count toward the number of guests</p>

							</div>
							<div class="modal-bottom">
								<button @click="guestsCommit">OK</button>
							</div>
						</div>
					</transition>

				</div>
				<div class="search__bedrooms">
					<button @click="bedroomsModal = true">
						<div class="search__arrival">
							bedrooms
						</div>
						<div class="search__bedroom-wrapper">
							<div class="search__number search__number--margin">
								{{ bedrooms | formatNumber }}
							</div>
							<img src="@/assets/img/arrow.svg"
							     alt="arrow">
						</div>
					</button>
					<transition name="show-modal">

						<div class="bedrooms-modal"
						     v-if="bedroomsModal">
							<div class="modal-topbar">
								<p>Bedrooms</p>
								<button @click="bedroomsModal = false"><img src="@/assets/img/small_close.svg"
									     alt="Close"></button>
							</div>
							<div class="modal-calc">
								<div class="modal-section">
									<p>Bedrooms</p>
									<div class="modal-buttons">
										<button @click="bedroomsMinus"
										        :class='{"grayColor" : bedrooms == 1}'>-</button>
										<span class="search__number"
										      :class='{"grayColor" : bedrooms == 0}'>{{ bedrooms | formatNumber }}</span>
										<button @click="bedroomsPlus">+</button>
									</div>
								</div>
								<div class="modal-section modal-section--border-bottom">
									<p>Kinderbed</p>
									<div class="modal-buttons">
										<button @click="kinderbedMinus"
										        :class='{"grayColor" : kinderbed == 0}'>-</button>
										<span class="search__number"
										      :class='{"grayColor" : kinderbed == 0}'>{{ kinderbed | formatNumber }}</span>
										<button @click="kinderbedPlus">+</button>
									</div>
								</div>
							</div>
							<div class="modal-bottom">
								<button @click="bedroomsCommit">OK</button>
							</div>
						</div>
					</transition>

				</div>
			</div>

			<div class="search__place"
			     id='search__place-id'>

				<p class="search__one search__one--custom search__one--center-mobile"></p>
				<span class="search__required">* required fields</span>
				<h2 class="search__choose search__choose--custom search__choose--center-mobile">Select one of our residences<sup>*</sup></h2>
				<transition name="component-fade"
				            mode="out-in">
					<div class="search__places"
					     v-show='!showVillaModal'>
						<div class="search__residence"
						     v-for="item in villas"
						     v-if="item.images"
						     :key="item.name"
						     :ref="item.id"
						     :class="{picked: (pickedVilla == item.id)}">
							<div class="search__image">
								<div class="search__hover">
									<button class="search__hover-button"
									        @click='showModal(item.id)'>Show Details</button>
									<button @click="pickVilla(item)"
									        class="search__hover-button"><span v-if="(pickedVilla == item.id)">Unselect</span><span v-else>Select</span></button>
								</div>
								<img :data-src="item.images[0].url"
								     class="lozad"
								     :alt="item.name">
							</div>
							<p class='search__residence-location'>{{item.location}}</p>
							<p class='search__residence-villa-name'>{{item.name}}</p>
						</div>
					</div>
				</transition>
				<transition name="component-fade"
				            mode="out-in">
                <keep-alive>

					<villa-booking v-if='showVillaModal'
					               :hide='hideModal'
					               :currentVilla="currentVilla"
                                   :pickedVilla="pickedVilla"
					               :pickVillaMethod='pickVilla'></villa-booking>
                </keep-alive>

				</transition>
			</div>

			<!-- AMENITIES & SERVICE -->
			<div class="search__amenitties">
				<div class="search__amenitties-left">
					<p class="search__one">AMENITIES & SERVICES</p>
					<h2 class="search__choose">Tailor your stay</h2>
				</div>
				<div class="search__amenitties-right">
					<span>For maximum personalisation with your Swiss luxury experience our concierge will contact you shortly in order to discuss the details of your stay. Please select additional services that you are interested in.</span>
					<div class="">
						<ul>
							<li v-for="addon in addons_list"
							    :key="addon + '_key'"
							    class="search__custom-li">
								<label :for="addon + 'checkbox'">
									<input type="checkbox"
									       :id="addon + 'checkbox'"
									       :name="addon + 'checkbox'"
									       :value="addon"
									       @change="commitAddons(addon)">
									{{addon}}
									<span @change="commitAddons(addon)"><svg width="16"
										     height="16"
										     viewBox="0 0 16 16"
										     fill="none"
										     xmlns="http://www.w3.org/2000/svg">
											<path d="M0.5 0.5L15.5 15.5M15.5 0.5L0.5 15.5"
											      stroke="#A66E5D" />
										</svg>
									</span>
								</label>
							</li>
						</ul>
					</div>
				</div>
			</div>


			<!-- Comments -->

			<div class="search__comments">
				<div class="search__comments-left">
					<h2 class="search__choose">Comments</h2>
					<p class="search__one">Please tell us about anything else you’d like included in your stay.</p>
				</div>
				<div class="search__comments-right">
					<textarea v-model="comments"
					          @change.lazy="commentsCommit"></textarea>
				</div>
			</div>

			<!-- Contact details -->

			<div class="search__contact">
				<h2 class="search__choose search__choose--center">Contact details</h2>
				<div class="search__fields">
					<form @submit.prevent="onSubmit">
						<div class="search__inputs">
							<input type="text"
							       placeholder="First name *"
							       name="name"
							       v-model="firstName"
							       @change.lazy="firstNameCommit"
							       required>
							<input type="text"
							       placeholder="Last name *"
							       name="name"
							       v-model="name"
							       @change.lazy="nameCommit"
							       required>
							<input type="email"
							       placeholder="E-mail adress *"
							       name="name"
							       v-model="email_address"
							       @change.lazy="emailAddressCommit"
							       required>
							<input type="text"
							       placeholder="Phone number *"
							       name="name"
							       v-model="phone_number"
							       @change.lazy="phoneNumberCommit"
							       required>
						</div>
						<div class="search__check">
							<ul>
								<li>
									<label for="terms">
										<input type="checkbox"
										       id="terms"
										       name="terms"
										       required>
										I agree to the Atrium Residences Privacy policy.
										<span>
											<svg width="16"
											     height="16"
											     viewBox="0 0 16 16"
											     fill="none"
											     xmlns="http://www.w3.org/2000/svg">
												<path d="M0.5 0.5L15.5 15.5M15.5 0.5L0.5 15.5"
												      stroke="#A66E5D" />
											</svg>
										</span>
										<sup>*</sup>
									</label>
								</li>
							</ul>

							<!-- <input type="checkbox" id="check" required>
                            <label for="check">This form collects your personal information so that our support team can contact you.</label> -->
						</div>
                        <vue-recaptcha
                            ref="recaptcha"
                            @verify="sendEnquiry"
                            @expired="onExpired"
                            size="invisible"
                            :sitekey="sitekey"
                        >
                        </vue-recaptcha>
						<input type="submit"
						       class="search__submit"
						       :value="submitButton">
						<div v-if="msg"
						     class="respond"
						     :class="{error: error}">{{msg}}</div>
					</form>
				</div>
			</div>


		</div>
	</div>

</div>
</template>

<script>
import Scrollbar from 'smooth-scrollbar';
import Datepicker from 'vuejs-datepicker';
import axios from "axios";
import VillaBookingInfo from './VillaBookingInfo.vue'
import VueRecaptcha from 'vue-recaptcha'
export default {
	name: 'search',
	filters: {
		formatNumber(number) {
			if (number <= 9) {
				return `0${number}`
			} else {
				return number
			}
		},
	},
	data() {
		return {
			showVillaModal: false,
			active: false,
			datefirst: false,
			datesecond: false,
			bedroomsModal: false,
			dateArrive: new Date(),
			dateDeparture: new Date(new Date()
				.setDate(new Date()
					.getDate() + 1)),
			currentVilla: 0,
			monthNames: [
				"January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"
			],
			adults: 1,
			disabledDatesArrive: {
				to: new Date(new Date()
					.setDate(new Date()
						.getDate() - 1))
			},
			kids: 0,
			infants: 0,
			bedrooms: 1,
			kinderbed: 0,
			name: "",
			firstName: "",
			comments: "",
			email_address: "",
			phone_number: '',
			addons_list: ["VIP Transportation throughout your stay", "Beauty & Wellness", "Detox & Diet", "Medical Services & Checkups", "Leisure Activities"],
			addons_picked: [],
			guests: false,
			submitButton: "Send",
			error: false,
			msg: "",
			show: false,
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY

		}
	},
	watch: {
		datefirst: function(newValue) {
			if (newValue) {
				this.datesecond = false;
				this.bedroomsModal = false;
				this.guests = false;
			}
		},
		datesecond: function(newValue) {
			if (newValue) {
				this.datefirst = false;
				this.bedroomsModal = false;
				this.guests = false;
			}
		},
		bedroomsModal: function(newValue) {
			if (newValue) {
				this.datefirst = false;
				this.datesecond = false;
				this.guests = false;
			}
		},
		guests: function(newValue) {
			if (newValue) {
				this.datefirst = false;
				this.datesecond = false;
				this.bedroomsModal = false;
			}
		},
		dateArrive: function(newValue, oldValue) {
			if ((this.dateDeparture < newValue) || (this.dateDeparture.toDateString() == newValue.toDateString())) {
				this.dateDeparture = new Date(new Date()
					.setDate(newValue.getDate() + 1))
				this.dateDeparture.setMonth(this.dateArrive.getMonth())
			}
			this.arriveCommit()
		},
		dateDeparture: function() {
			this.departureCommit()
		}
	},
	components: {
		'datepicker': Datepicker,
		'villa-booking': VillaBookingInfo,
		'vue-recaptcha': VueRecaptcha
	},
	computed: {
		disabledDates() {
			let oneDayMore = new Date(new Date()
				.setDate(this.dateArrive.getDate()))
			oneDayMore.setMonth(this.dateArrive.getMonth());
			return {
				to: oneDayMore
			}
		},
		villas() {
			return this.$store.getters.getAllVillas;
		},
		pickedVilla() {
			return this.$store.getters.getPickedVilla;
		}
	},
	methods: {
        onSubmit(){
            this.sendEnquiry();
        },
		navigate(url) {
			this.active = false
			const el = document.querySelector(url);

			if (el) {
				this.$bamUtils.scroll.scrollIntoView(el);
			}

		},
		showModal(idVilla) {
			this.showVillaModal = true;
			const villa = this.villas.filter((el) => {
				if (el.id === idVilla) {
					return el
				}
			});
			this.currentVilla = villa[0];

		},
		hideModal() {
			this.showVillaModal = false;
			setTimeout(() => {
				this.navigate('#search__place-id')
			}, 500)
		},
		arriveCommit() {
			this.datefirst = false;
			this.$store.commit("SET_ARRIVE_DATE", this.dateArrive);
		},
		departureCommit() {
			this.datesecond = false;
			this.$store.commit("SET_DEPARTURE_DATE", this.dateDeparture);
		},
		guestsCommit() {
			this.$store.commit("SET_ADULTS", this.adults);
			this.$store.commit("SET_KIDS", this.kids);
			this.$store.commit("SET_INFANTS", this.infants);
			this.guests = false;
		},
		bedroomsCommit() {
			this.bedroomsModal = false;
			this.$store.commit("SET_BEDROOMS", this.bedrooms);
			this.$store.commit("SET_KINDERBEDS", this.kinderbed);
		},
		bedroomsMinus() {
			if (this.bedrooms - 1 == 0) {
				return
			} else {
				this.bedrooms--
			}
		},
		bedroomsPlus() {
			if (this.bedrooms + 1 == 21) {
				return
			} else {
				this.bedrooms++
			}
		},
		kinderbedMinus() {
			if (this.kinderbed - 1 == -1) {
				return
			} else {
				this.kinderbed--
			}
		},
		kinderbedPlus() {
			if (this.kinderbed + 1 == 21) {
				return
			} else {
				this.kinderbed++
			}
		},
		adultsMinus() {
			if (this.adults - 1 == 0) {
				return
			} else {
				this.adults--
			}
		},
		adultsPlus() {
			if (this.adults + 1 == 21) {
				return
			} else {
				this.adults++
			}
		},
		kidsMinus() {
			if (this.kids - 1 == -1) {
				return
			} else {
				this.kids--
			}
		},
		kidsPlus() {
			if (this.kids + 1 == 21) {
				return
			} else {
				this.kids++
			}
		},
		infantsMinus() {
			if (this.infants - 1 == -1) {
				return
			} else {
				this.infants--
			}
		},
		infantsPlus() {
			if (this.infants + 1 == 21) {
				return
			} else {
				this.infants++
			}
		},
		commitAddons(addon) {
			this.$store.commit("SET_ADDONS", addon);
		},
		nameCommit() {
			this.$store.commit("SET_LAST_NAME", this.name);
		},
		firstNameCommit() {
			this.$store.commit("SET_FIRST_NAME", this.firstName);
		},
		commentsCommit() {
			this.$store.commit("SET_COMMENTS", this.comments);
		},
		phoneNumberCommit() {
			this.$store.commit("SET_PHONE_NUMBER", this.phone_number);
		},
		emailAddressCommit() {
			this.$store.commit("SET_EMAIL_ADDRESS", this.email_address);
		},
		validateEnquiryData(enquiryData) {
			if (!enquiryData.last_name.length) {
				return "Last name is required."
			}
			if (!enquiryData.first_name.length) {
				return "First name is required."
			}

			if (!enquiryData.email_address.length) {
				return "Email address is required."
			}

			if (!enquiryData.arrive_date) {
				return "Arrive date is required."
			}

			if (!enquiryData.departure_date) {
				return "Departure date is required."
			}

			if (!enquiryData.adults || enquiryData.adults < 1) {
				return "Minimum 1 adult is required."
			}

			if (!enquiryData.bedrooms || enquiryData.bedrooms < 1) {
				return "Minimum 1 bedrooms is required."
			}

			if (!enquiryData.picked_villa_name) {
				return "Please pick villa."
			}

			return true;
		},
		sendEnquiry() {
            // this.$store.commit("RECAPTCHA_TOKEN", recaptchaToken);
			let validation = this.validateEnquiryData(this.$store.getters.getEnquiryData)
			if (validation === true) {
				this.error = false
				this.msg = ""
				this.submitButton = "Sending..."
				this.$store.dispatch('sendEnquiryForm')
					.then((resp) => {
						if (resp.error) {
							this.error = true;
						} else {
							this.$router.push('/thank-you/');
						}
						this.msg = resp.msg;
						this.submitButton = "Send";

					});
			} else {
				this.error = true;
				this.msg = validation;
			}

			setTimeout(() => {
				this.msg = "";
				this.error = false;
			}, 3000);

		},
        onExpired(){
            this.$store.commit("RECAPTCHA_TOKEN",null);
        },
		pickVilla(item) {
			this.$store.commit("SET_PICKED_VILLA", item)
		}
	},
	mounted() {
		this.$bamUtils.syncEnquiry(this)
	},
}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';
@import '@/styles/framework/buttons.scss';
.active_contact_link {
    &--without-decoration {
        text-decoration: none;
    }
    color: #66625F;
    will-change: color;
    transition: color 200ms;
    &:hover {
        color: $brown;
    }
}
.search {
    position: relative;
    &__inner-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        @include breakpoint-max('tablet') {
            transform: translateX(5px);
        }
    }
    &__arrival-span {
        display: none;
        @include breakpoint-max('tablet') {
            display: initial;
        }
        &--hide-on-mobile {
            display: initial;
            margin: 0 10px;
            @include breakpoint-max('tablet') {
                display: none;
                margin-right: 0;

            }
        }
    }
    &__color {
        color: $gold_sand;
    }
    &__hover {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: 200;
        width: 100%;
        height: 100%;
        transition: all 0.8s;
        background-color: rgba(46, 46, 51, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

    }
    &__hover-button {
        @include button;
        &:after {
            background-color: rgba(46, 46, 51, 1);
        }
        transition: transform 0.6s;
        color: #fff;
        text-transform: uppercase;
        font-family: "Poppins";
        font-size: 1.4rem;
        line-height: 1.7rem;
        background: transparent;
        border: 1px solid $gold_sand;
        margin: 10px 0;
        min-height: 40px;
        min-width: 200px;
        transform: translateX(-110%);
        &:last-child {
            transform: translateX(110%);
        }
        @include breakpoint-max('mobile') {
            min-width: 80%;
            max-width: 80%;
        }
    }
    &__overlay {
        background-color: $beige;
    }
    &__amenitties-right {
        li,
        span {
            font-weight: 400 !important;
        }
    }
    &__required {
        color: $brown;
        width: 100%;
        display: block;
        @include breakpoint-max('xs-tablet') {
            text-align: center;
        }
    }
    &__select {
        width: 100%;
        max-width: 1440px;
        min-height: 120px;
        width: 100%;
        background-color: #FFF;
        bottom: 0;
        position: absolute;
        z-index: 800;
        transform: translateY(80px);
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 60px;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.13);
        @include breakpoint-max('tablet') {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 1px 1px;
            grid-template-areas: "search__date-first search__date-second . ." "search__guests search__bedrooms . ." "search__enquire search__enquire . .";
            width: calc(100% - 60px);
            left: 50%;
            transform: translateX(-50%) translateY(80px);
            column-gap: 0;
        }
        &--custom {
            grid-template-columns: repeat(4, 1fr);
            background-color: transparent;
            box-shadow: none;
            border-top: 1px solid rgba(102, 98, 95, 0.3);
            border-bottom: 1px solid rgba(102, 98, 95, 0.3);
            position: relative;
            transform: none;
            @include breakpoint-max('tablet') {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                gap: 1px 1px;
                justify-items: center;
                // grid-template-areas: "search__date-first search__date-second . " "search__guests search__bedrooms  ." "search__enquire search__enquire .";
                width: calc(100% - 0px);
                left: 0;
                // transform: translateX(-50%) translateY(80px);
                column-gap: 0;
            }
        }
    }
    &__custom-li {
        color: $text_color;
    }
    &__enquire {
        @include breakpoint-max('tablet') {
            grid-area: search__enquire;
            padding-bottom: 15px;
        }
        button {
            font-size: 1.4rem;
            width: 100%;
            height: 100%;
            border: 0;
            outline: none;
            background-color: $gold_sand;
            color: #FFF;
            text-transform: uppercase;
            transition: background-color 0.8s;
            @include breakpoint-max('tablet') {
                width: 80%;
                height: 85%;
                font-size: 1.2rem;
                display: block;
                margin: 0 auto;
            }
            &:hover {
                background-color: darken($gold_sand, 10%);
            }
        }
    }
    &__calendar {
        margin: 0 5px;
    }
    &__date-first-wrapper {
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 1/4;
    }
    &__date-second-wrapper {
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 1/4;
    }
    &__bedroom-wrapper {
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 1/4;
    }
    &__guest-wrapper {
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 1/4;
    }
    &__date-first,
    &__date-second {
        padding: 0 20px;
        position: relative;
        @include breakpoint-max('tablet') {
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        @include breakpoint-max('mobile') {
            padding: 15px 0;
            position: static;
        }

        button {
            outline: none;
            background-color: transparent;
            border: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 180px;
            @include breakpoint-max('tablet') {
                max-width: 130px;
            }
        }
        & > button {
            @include breakpoint-max('tablet') {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
            }
        }
    }
    &__date-first {
        @include breakpoint-max('tablet') {
            grid-area: search__date-first;
        }

    }
    &__date-second {
        & > button {
            @include breakpoint-max('tablet') {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
            }
        }
        @include breakpoint-max('tablet') {
            grid-area: search__date-second;
        }

    }
    &__arrow {
        outline: none;
        border: 0;
    }
    &__datepicker {
        z-index: 800;
    }
    &__arrival {
        text-transform: uppercase;
        font-size: 1.4rem;
        @include breakpoint-max('tablet') {
            grid-column: 1/ 4;
        }
        @include breakpoint-max('mobile') {
            font-size: 1rem;
        }
    }
    &__bedrooms,
    &__guests {
        padding: 0 20px;
        position: relative;
        @include breakpoint-max('tablet') {
            padding: 0 20px 30px;
        }
        @include breakpoint-max('mobile') {
            padding: 15px 0;
            position: static;
        }
        button {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 0;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 180px;

            .hero__arrow {
                margin-left: 8px;
            }
            .hero__arrival {
                margin-right: 8px;
            }
            @include breakpoint-max('tablet') {
                max-width: 130px;
            }
        }
        & > button {
            @include breakpoint-max('tablet') {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
            }
        }
    }
    &__guests {
        @include breakpoint-max('tablet') {
            grid-area: search__guests;
        }
    }
    &__bedrooms {
        @include breakpoint-max('tablet') {
            grid-area: search__bedrooms;
        }
    }
    &__number {
        font-size: 4.4rem;
        font-family: 'Financier';
        @include breakpoint-max('mobile') {
            font-size: 2.8rem;
        }
        &--margin {
            margin: 0 10px;
        }
    }
    &__month {
        text-transform: uppercase;
        color: $brown;
        font-size: 1.4rem;
        @include breakpoint-max('mobile') {
            font-size: 1rem;
        }
    }
    &__year {
        color: $text_color;
        line-height: 15px;
        font-size: 1.4rem;
        @include breakpoint-max('mobile') {
            font-size: 1rem;
        }
    }

    // Overlay
    &__close {
        width: 40px;
        height: 40px;
        outline: none;
        border: 0;
        position: fixed;
        right: 40px;
        top: 40px;
        z-index: 995;
        background-color: transparent;
        @include tabletSize {
            top: 65px;
            right: 15px;
        }
    }
    &__place {
        padding-bottom: 80px;
        border-bottom: 1px solid rgba(102, 98, 95, 0.3);
    }
    &__places {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 27px;
        @include breakpoint-max('tablet') {
            // grid-template-columns: repeat(2, 1fr);
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: row;
        }

    }
    &__residence {
        width: 100%;
        @include breakpoint-max('tablet') {
            width: 45%;
            margin: 0 2.5%;
        }
        &.picked .search__image::after {
            opacity: 1;
        }
        &.picked .search__image::before {
            opacity: 0.7;
        }

        .search__image {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: $gold_sand;
                opacity: 0;
                will-change: opacity;
                transition: opacity 0.1s;
            }
            &::after {
                content: 'SELECTED RESIDENCE';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                opacity: 0;
                z-index: 100;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: 2rem;
            }
        }
    }
    &__residence-location {
        color: $text_color;
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-family: "Poppins";
        font-weight: normal;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        display: block;
        margin-top: 5px;
    }
    &__residence-villa-name {
        color: $brown;
        text-align: center;
        text-transform: uppercase;
        font-size: 2.3rem;
        margin: 0 0 10px;
    }
    &__image {
        width: 100%;
        height: 220px;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        &:hover {
            & .search__hover {
                opacity: 1;

            }
            & .search__hover-button {
                transform: translateX(0);
            }
        }
        &.not-enough-bedrooms {
            filter: grayscale(100%);
            &::after {
                content: 'NOT ENOUGH BEDROOMS';
                position: absolute;
                left: 50%;
                top: 50%;
                z-index: 1;
                transform: translate(-50%,-50%);
                opacity: 1;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                background: $black_brown;
                opacity: 0.6;
            }
            &:hover {
                & .search__hover {
                    opacity: 0;

                }
                & .search__hover-button {
                    transform: translateX(200%);
                }
            }
        }
    }
    &__header {
        font-size: 2.3rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: 300;
        color: $brown;
        margin-bottom: 20px;
    }
    &__one {
        font-size: 1.4rem;
        color: $black_brown;
        &--custom {
            margin-top: 80px;
            @include breakpoint-max('xs-tablet') {
                margin-left: 20px;
                margin-right: 20px;
            }
        }
        &--center {
            @include breakpoint-max('xs-tablet') {
                text-align: center;
            }
        }
        &--center-mobile {
            @include breakpoint-max('xs-tablet') {
                text-align: center;
            }
        }
    }
    &__choose {
        font-size: 4.4rem;
        color: $black_brown;
        font-family: 'Financier';
        font-weight: 300;
        margin-bottom: 12px;
        @include breakpoint-max('xs-tablet') {
            font-size: 2.6rem;
        }
        &--center {
            text-align: center;

        }
        &--custom {
            @include breakpoint-max('xs-tablet') {
                margin: 0 20px 20px;
            }
            sup {
                color: $gold_sand;
                font-size: 3rem;
            }
        }
        &--center-mobile {
            @include breakpoint-max('xs-tablet') {
                text-align: center;
            }
        }
    }

    // Amenities

    &__amenities {
        border-bottom: 1px solid rgba(102, 98, 95, 0.3);
        display: flex;
        padding: 80px 0;
    }
    &__image-amenities {
        flex: 1;
        margin-left: 50px;
        margin-right: 60px;
        @include breakpoint-max('s-tablet') {
            display: none;
        }
    }
    &__services {
        flex: 4;
        @include breakpoint-max('s-tablet') {
            margin: 0 50px;
        }
        @include breakpoint-max('xs-tablet') {
            margin: 0 20px;
        }
    }
    &__lists {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-right: 50px;
        @include breakpoint-max('tablet') {
            flex-direction: column;
            margin-right: 0;
            align-items: center;
        }
    }
    &__list {
        margin-right: 20px;
        margin-top: 40px;
        span {
            font-size: 1.4rem;
            color: $brown;
        }
        ul {
            margin-top: 10px;
            list-style: none;
            li {
                color: $text_color;
                line-height: 37px;
            }
        }
        @include breakpoint-max('tablet') {
            margin-right: 0;
            text-align: center;
            padding: 5%;
            border: 1px solid #E2DAD2;
        }
    }

    // Comments

    &__comments {
        display: flex;
        padding: 80px 0;
        border-bottom: 1px solid rgba(102, 98, 95, 0.3);
        @include breakpoint-max('xs-tablet') {
            flex-direction: column;
        }
        &-left {
            flex: 2;
            margin-left: 50px;
            @include breakpoint-max('xs-tablet') {
                margin: 0 20px;
                text-align: center;
            }
        }
        &-right {
            flex: 4;
            margin-right: 50px;
            @include breakpoint-max('xs-tablet') {
                margin: 20px 0 0;
                flex: 100%;
            }
            textarea {
                width: 100%;
                min-height: 300px;
                background-color: transparent;
                resize: none;
                outline: none;
                padding: 20px;
                font-size: 2rem;
                border: 1px solid $text_color;
                opacity: 0.5;
                font-family: 'Poppins', sans-serif;
                @include breakpoint-max('xs-tablet') {
                    width: calc(100% - 40px);
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    }

    // amenitties

    &__amenitties {
        display: flex;
        padding: 80px 0;
        border-bottom: 1px solid rgba(102, 98, 95, 0.3);
        @include breakpoint-max('xs-tablet') {
            flex-direction: column;
        }
        &-left {
            flex: 2;
            margin: 0 50px;
            border-right: 1px solid $dark_beige;
            display: flex;
            justify-content: center;
            flex-direction: column;
            @include breakpoint-max('xs-tablet') {
                display: block;
                margin: 0 20px;
                text-align: center;
                border-right: none;

            }
        }
        &-right {
            flex: 4;
            margin-right: 50px;
            @include breakpoint-max('xs-tablet') {
                margin: 20px 0 0;
                flex: 100%;
            }
            span {
                margin-bottom: 20px;
                font-size: 1.7rem;
                color: $text_color;
                font-family: "Poppins";
                line-height: 2.7rem;
                font-weight: 500;
                display: block;
                @include breakpoint-max('xs-tablet') {
                    width: calc(100% - 40px);
                    text-align: center;
                    margin: 0 auto 30px;
                    font-size: 1.3rem;
                    line-height: 2.3rem;

                }
            }
            ul {
                max-height: 180px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                list-style: none;
                align-items: flex-start;
                @include breakpoint-max('xs-tablet') {
                    max-height: initial;
                    flex-wrap: nowrap;
                    text-align: center;
                }
                li {
                    color: $text_color;
                    font-family: "Poppins";
                    font-size: 1.7rem;
                    padding: 12px 5px 12px 35px;
                    width: 50%;
                    @include breakpoint-max('xs-tablet') {
                        margin: 0;
                        padding: 12px 0 10px 50px;
                        width: calc(100% - 50px);
                        margin: 0 auto;
                        text-align: left;
                        font-size: 1.3rem;
                        line-height: 2.3rem;
                    }
                }
            }
            label {
                position: relative;
                transition: color 0.6s;
                span {
                    position: absolute;
                    top: 4px;
                    left: -30px;
                    height: 16px;
                    width: 16px;
                    border: 1px solid $brown;
                    background-color: transparent;
                    @include breakpoint-max('xs-tablet') {
                        top: 1px;
                    }
                    svg {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        height: 15px;
                        width: 15px;
                        position: absolute;
                        opacity: 0;
                        transition: opacity 0.6s;
                    }
                }
            }

            input {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;
            }
        }
        input:checked + span svg {
            opacity: 1;
        }

    }
    // Contact

    &__contact {
        padding: 47px 0 80px;
    }
    &__check {
        max-width: 700px;
        width: 100%;
        margin: 20px auto;
        display: flex;
        align-items: center;
        color: $text_color;
        justify-content: center;
        @include breakpoint-max('mobile') {
            width: calc(100% - 40px);
        }
        sup {
            color: $gold_sand;
            font-size: 1.8rem;
        }
        span {
            margin-bottom: 20px;
            font-size: 1.7rem;
            color: $text_color;
            font-family: "Poppins";
            line-height: 2.7rem;
            font-weight: 500;
            display: block;
            @include breakpoint-max('xs-tablet') {
                width: calc(100% - 40px);
                text-align: center;
                margin: 0 auto 30px;
                font-size: 1.3rem;
                line-height: 2.3rem;

            }
        }
        ul {
            max-height: 180px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            list-style: none;
            align-items: flex-start;
            @include breakpoint-max('xs-tablet') {
                max-height: initial;
                flex-wrap: nowrap;
                text-align: center;
            }
            li {
                color: $text_color;
                font-family: "Poppins";
                font-size: 1.7rem;
                padding: 12px 5px 12px 35px;
                width: 100%;
                @include breakpoint-max('xs-tablet') {
                    margin: 0;
                    padding: 12px 0 10px 50px;
                    width: calc(100% - 50px);
                    margin: 0 auto;
                    text-align: left;
                    font-size: 1.3rem;
                    line-height: 2.3rem;
                }
            }
        }
        label {
            position: relative;
            transition: color 0.6s;
            span {
                position: absolute;
                top: 4px;
                left: -30px;
                height: 16px;
                width: 16px;
                border: 1px solid $brown;
                background-color: transparent;
                @include breakpoint-max('xs-tablet') {
                    top: 1px;
                }
                svg {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 15px;
                    width: 15px;
                    position: absolute;
                    opacity: 0;
                    transition: opacity 0.6s;
                }
            }
        }

        input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
        }
        input:checked + span svg {
            opacity: 1;
        }
    }
    &__inputs {
        display: flex;
        justify-content: center;
        max-width: 800px;
        width: 100%;
        margin: 20px auto 0;
        @include breakpoint-max('mobile') {
            width: calc(100% - 40px);
            flex-direction: column;

        }
        input {
            font-size: 1.7rem;
            background: transparent;
            border: 0;
            color: $text_color;
            border: 1px solid $dark_beige;
            padding: 15px 17px;
            outline: none;
            max-width: 350px;
            width: 100%;
            flex: 1;
            border-radius: 0;
            -webkit-appearance: none;
            @include breakpoint-max('mobile') {
                flex: 100%;
                max-width: none;
                appearance: none;
            }
            &:nth-child(2) {
                margin-right: 8px;
                @include breakpoint-max('mobile') {
                    margin-right: auto;
                    margin-bottom: 8px;
                }
            }
            &:first-child {
                margin-right: 8px;
                margin-left: 8px;
                @include breakpoint-max('mobile') {
                    margin-right: auto;
                    margin-bottom: 8px;
                    margin-left: 0;
                }
            }
            &:last-child {
                margin-left: 8px;
                margin-right: 8px;
                @include breakpoint-max('mobile') {
                    margin-left: auto;
                    margin-top: 8px;
                }
            }
        }
    }
    &__submit {
        padding: 15px 17px;
        outline: none;
        max-width: 350px;
        width: 100%;
        margin: 8px;
        font-size: 1.7rem;
        background: transparent;
        border: 0;
        background-color: $gold_sand;
        color: #FFF;
        text-transform: uppercase;
        display: block;
        margin: 0 auto;
        appearance: none;
        border-radius: 0;
        @include breakpoint-max('mobile') {
            max-width: none;
            width: calc(100% - 40px);
            appearance: none;
        }
    }
}

.bedrooms-modal,
.date-first-modal,
.date-second-modal,
.guests-modal {
    position: absolute;
    top: 0;
    display: block;
    left: 0;
    padding: 30px;
    background-color: #FFF;
    z-index: 800;
    min-width: 350px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.13);
    @include breakpoint-max('big') {
        left: 50%;
        transform: translateX(-50%);
    }
}

.modal-topbar {
    text-align: center;
    border-bottom: 1px solid #E0E0E0;
    p {
        color: $black_brown;
        font-size: 1.4rem;
        margin-bottom: 14px;
        text-transform: uppercase;
    }
    button {
        position: absolute;
        width: 35px;
        height: 35px;
        right: 15px;
        top: 15px;
        padding: 10px;
    }
}

.modal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        width: 30px;
        font-size: 2rem;
    }
}

.modal-section {
    text-align: center;
    margin: 30px 0;
    &--border-bottom {
        margin: 30px 0 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #E0E0E0;
    }
    p {
        text-transform: uppercase;
        color: #828282;
        font-size: 1.4rem;
    }
}

.modal-bottom {
    button {
        max-width: 100%;
        color: $brown;
        font-size: 1.4rem;
        padding: 5px 0;
    }
}

.modal-bottom-custom {
    button {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        color: $brown;
        font-size: 1.4rem;
        padding: 5px 10px;
        &.btn {
            max-width: none !important;
        }
    }
}

.custom-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding-top: 270px;
}

// Datepicker customization

/deep/.vdp-datepicker__calendar .cell.selected,
/deep/.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
    border-radius: 50%;
    border: 1px solid $gold_sand;
    background: none;
}

.respond {
    color: green;
    padding-top: 10px;
    text-align: center;
    &.error {
        color: red;
    }
}
.modal-info-text {
    color: #828282;
    font-size: 1.5rem;
    line-height: 2.3rem;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 20px;
}
.grayColor {
    color: #BDBDBD;
}
.component-fade-enter-active,
.component-fade-leave-active {
    transition: opacity 0.3s ease;
}
/* .component-fade-leave-active below version 2.1.8 */
.component-fade-enter,
.component-fade-leave-to {
    opacity: 0;
}
.show-modal-enter-active,
.show-modal-leave-active {
    transition: all 0.4s;
    max-height: 500px;
    overflow: hidden;
}

.show-modal-child-active,
.show-modal-enter,
.show-modal-leave-to,
.show-modal-leave-to {
    opacity: 0;
    max-height: 0;
}
#check {
    @include mobile {
        appearance: none;
    }
}
.search__image.not-enough-bedrooms {
    .search__image::after {
        opacity: 1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(102, 98, 95, 0.5), rgba(102, 98, 95, 0.5));
    }

}
/deep/.grecaptcha-badge{
    bottom: 0 !important;
    right: 0 !important;
}
</style>
