<template>
    <section class="team">
        <div class="team__background">
            <img :src="data.background" alt="">
        </div>
        <div class="team__mission">

            <svg width="963" height="690" viewBox="0 0 963 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M481.268 612.091C325.204 612.091 198.402 484.964 198.402 328.5C198.402 172.036 325.668 44.9086 481.268 44.9086C636.867 44.9086 764.133 172.036 764.133 328.5C764.133 484.964 637.332 612.091 481.268 612.091ZM962 328.5C962 63.0696 746.483 -153 481.732 -153C216.517 -153 1 63.0696 1 328.5C1 593.93 216.517 810 481.268 810C587.633 810 685.637 775.075 765.527 716.401L765.991 800.221H962V328.5Z" stroke="#D1BEA6" stroke-opacity="0.5" stroke-miterlimit="10"/>
            </svg>

            <div class="team__heading team__heading--mision">
                <h2>ABOUT US</h2>
                <p>
                    <span>Our Purpose.</span> We are reinventing the experience of care.
                </p>
                <p>
                    <span>Our Vision.</span> We are the global leaders in innovative care services.
                </p>
                <p>
                    <span>Our Missions.</span> To provide discerning travellers with an unparalleled custom holiday experience in our private luxury Swiss residences that include full-service accommodations.
                </p>
            </div>
        </div>
        <div class="team__container">
            <div class="team__heading">
                <h2>{{data.title}}</h2>
                <p v-html="data.content"></p>
            </div>
            <div class="team__box">
                <!-- <div class="team__gray-bgc"></div> -->
                <router-link v-for="member in teamMembers" :to="member.link" class="team__person"  :key="member.id">
                    <div class="team__img">
                        <img :data-src="member.thumbnail" class="lozad" :alt="generateAlt(member)">
                    </div>
                    <h3 class="name">{{member.title}}</h3>
                    <span v-if="member.position">{{member.position}}</span>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    computed: {
        teamMembers() {
            return this.$store.getters.getTeamMembers
        }
    },
	methods: {
		generateAlt(member){
			if(member.position){
				return member.title + ' - ' + member.position
			} else return member.title
		}
	}
}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';

.team {
    padding: 160px 0 160px 80px;
    position: relative;
    @include tabletSize {
        padding: 0;
    }
    &__mission{
        background-color: $beige;
        position: relative;
        svg{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__background {
        position: absolute;
        width: 100%;
        height: 50%;
        top: -160px;
        left: 0;
        z-index: -1;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        @include tabletSize {
            display: none;
        }
    }
    &__container {
        background-color: #FFF;
        @include mobile {
            background-color: $white;
        }
    }
    &__heading {
        padding: 84px 240px 180px 240px;
        text-align: center;


        h2 {
            font-size: $small_heading;
            color: $brown;
            text-transform: uppercase;
            font-weight: 400;
        }
        p {
            font-size: $big_heading;
            color: $black_brown;
            font-family: Financier;
            font-weight: 400;
        }
        &--mision{
            h2{
                margin-bottom: 50px;
            }
            p{
                margin-bottom: 30px;
                color: $brown;
                &:last-child{
                    margin-bottom: 0;
                }
                span{
                    color: $black_brown;
                }
            }
        }
        @include breakpoint-max('big') {
            padding: 84px 180px 180px 180px;
        }
        @include mobile {
            padding: 100px 60px 60px 60px;
        }
        @include tabletSize {
            h2 {
                font-size: $small_txt;
            }
            p {
                font-size: $mobile_heading;
            }
        }
    }
    &__person {
        text-align: center;
		text-decoration: none;
		.name {
			will-change: color;
			transition: color 200ms;
		}
		&:hover {
			.name {
				color: $brown;
			}
		}
    }
    &__gray-bgc {
        display: none;
        @include tabletSize {
            display: block;
            position: absolute;
            top: -30px;
            left: 0;
            width: 100%;
            height: 15%;
            background-color: $gray;
        }
    }
    &__box {
        padding: 0 100px 187px 100px;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 70px 40px;
        @include breakpoint-max('m-large') {
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint-max('big') {
            grid-template-columns: repeat(2, 1fr);
        }
        @include mobile {
            position: relative;
            grid-template-columns: 1fr;
            padding: 54px 25px 66px 25px;
        }
    }
    &__person {
        img {
            height: 200px;
            width: 200px;
            border-radius: 50%;
            object-fit: cover;
            // filter: grayscale(100%);
            // object-position: top;
        }
        span {
            font-size: $mobile_txt;
            color: $brown;
        }
        h3 {
            font-size: $big_txt;
            font-family: Financier;
            color: $black_brown;
            font-weight: 400;
        }
        @include tabletSize {
            text-align: center;
            z-index: 10;
        }
        @include mobile {
            h3 {
                font-size: $mobile_heading;
            }
        }
    }
}

</style>
