<template>
    <section class="newsletter" id="newsletter" v-if='newsletter'>
            <div class="shadow"></div>
            <div class="newsletter__front">
                <h2 class="newsletter__title" v-html="newsletter.title"></h2>
                <button @click.prevent="activeOverlay = true" class="newsletter__btn">{{newsletter.button.text}}</button>
            </div>
            
            <template v-if='newsletter.is_video'>
				<lazy-video :bamSrc="newsletter.video.url" :bamPoster="newsletter.poster.url" class='newsletter__video'  v-show="windowWidth > 801"></lazy-video>
				<lazy-video :bamSrc="newsletter.video_mobile_2.url" :bamPoster="newsletter.poster.url" class='newsletter__video' v-show=" windowWidth < 499"></lazy-video>
				<lazy-video :bamSrc="newsletter.video_tablet_2.url" :bamPoster="newsletter.poster.url" class='newsletter__video' v-show="windowWidth > 500 && windowWidth <  800"></lazy-video>

            </template>
            <template v-else>
				  <lazy-image class='newsletter__video' :bamSmallImage="newsletter.img_background.sizes.size50x33" :bamFullImage="newsletter.img.background.url"></lazy-image>
            </template>
        <transition
            v-on:enter="newsEnter"
            v-on:leave="newsLeave"
            appear
        >
            
            <form class="newsletter__back" v-if="activeOverlay">
                <div class="">
                  <h3 v-html="newsletter.second_title"></h3>
					  <form @submit.prevent="signUpToNewsletter">
						  <div class="newsletter__inputs">
							  <input type="text" value="" placeholder="Your name" required v-model="newsletterName">
							  <input type="email" value="" placeholder="E-mail address" required v-model="newsletterEmail">
						  </div>
						  <input type="submit" class="newsletter__button" :value="submitText">
						  <div class="newsletter_output_message">
  							<span :class="{newsletterError: newsletterError}" v-if="newsletterOutputMessage">{{newsletterOutputMessage}}</span>
  						</div>
					  </form>
                </div>

                <p v-html="newsletter.text"></p>
                <button class="newsletter__close" @click.prevent="activeOverlay = false">
                    <img src="../../../assets/img/exit.png" alt="">
                </button>
            </form>

        </transition>
        <transition
            v-on:enter="thanksEnter"
            v-on:leave="thanksLeave"

        >
            <div v-show="activeThanks" class="newsletter__thanks">
                <h2 class="newsletter__thanks-txt">{{newsletter.thanks}}</h2>
                <button class="newsletter__thanks-btn" @click.prevent="activeThanks = false, activeOverlay = false">
                    <img src="../../../assets/img/exit.png" alt="">
                </button>
            </div>
        </transition>
    </section>


</template>

<script>
import {
	TimelineLite,
	Back,
	Elastic,
	Expo
} from "gsap"
import axios from "axios";
export default {
    computed: {
		newsletter(){
	        return this.$store.getters.getNewsletter
	    }
    },
    
    data() {
        return {
            activeOverlay: false,
            activeThanks:false,
			newsletterName: "",
			newsletterEmail: "",
			newsletterError: false,
			newsletterOutputMessage: "",
            submitText: "Sign up",
            windowWidth: 0,
            
        }
    },
    mounted(){
        // let video = this.$refs.video;
        // if (video) {
        //     this.$bamUtils.videoObserver(video)
        // }
    },
    methods: {
          handleResize() {
      this.windowWidth = window.innerWidth;
        },
		signUpToNewsletter(){
			this.submitText = "Registration is ongoing...";
			axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/newsletter',{
				name: this.newsletterName,
				email: this.newsletterEmail
			}).then(response => {
				if(response.data === "ok"){
					this.activeThanks = true
                    // setTimeout(() => {
                    //     this.activeThanks = false
                    // }, 4000)

				} else if(response.data == "Member Exists"){
					this.newsletterOutputMessage = "You are already in our mailing list.";
					this.newsletterError = true;
				} else {
					this.newsletterOutputMessage = response.data;
					this.newsletterError = true;
				}
				this.submitText = "Sign up";
				this.resetOutputMessage();
			})
		},
		resetOutputMessage(){
			setTimeout(() => {
				this.newsletterOutputMessage = "";
				this.newsletterError = false;
			}, 4000);
		},
        newsEnter(el, done) {
            const inputs = document.querySelectorAll('.newsletter__inputs');
			const tl1 = new TimelineMax({
				onComplete: done
            })
            tl1.set(inputs, {
                opacity: 0,
            })
            tl1.set('.newsletter__back', {
              		// y: '-100%',
				// transformOrigin: "0% top"
            })
            tl1.set('.newsletter__back h3', {
                opacity: 0,
            })
            tl1.set('.newsletter__close img', {
                opacity: 0,
            })
            tl1.set('.newsletter__back p', {
                opacity: 0,
            })
            tl1.set('.newsletter__button', {
                opacity: 0,
            })

            tl1.to('.newsletter__back', .5, {
            		y: 0,
				ease: Power2.easeOut

            },.9)
            tl1.to('.newsletter__thanks', 1,{
                opacity: 0
            })
            tl1.to('.newsletter__back h3', .8,{
                opacity:1,
            }) 
            tl1.staggerTo(inputs,.8 ,{
                opacity: 1 , stagger: .6  },.5)

             tl1.to('.newsletter__button', .8,{
                opacity:1,
            })
            tl1.to('.newsletter__back p', .8,{
                opacity:1,
                stagger: .5,
            })  
            tl1.to('.newsletter__close img', .8,{
                opacity:1,
            })
            tl1.timeScale(3)

		},
        newsLeave(el, done) {
            const inputs = document.querySelectorAll('.newsletter__inputs');

			const tl2 = new TimelineMax({
				onComplete: done
            })
            tl2.to('.newsletter__back p', .8,{
                opacity:0,
                stagger: .5,
            }) 
                tl2.to('.newsletter__button', .8,{
                opacity:0,
            })
            tl2.staggerTo(inputs,.8 ,{
                opacity: 0 , stagger: .6  },.5
            )
            tl2.to('.newsletter__back h3', .8,{
                opacity:0,
            }) 
            tl2.to('.newsletter__close img',.8, {
                opacity:0,
            })
            tl2.to('.newsletter__back', 1, {
                 	y: '-100%',
                 stagger: .6
            })
            tl2.to('.newsletter__thanks', 1,{
                opacity: 0
            })
            tl2.timeScale(3)
        


		},
        thanksEnter(el,done) {
            const tl1 = new TimelineMax({
				onComplete: done
			})

            tl1.set('.newsletter__thanks', {
                x: '-100%',
                opacity: 0
            })
            tl1.set('.newsletter__thanks-btn', {
                opacity: 0
            })

            tl1.to('.newsletter__thanks', .2, {
                x: '100%',
                opacity: 1

            })
            tl1.to('.newsletter__thanks-btn', 1, {
                opacity: 1

            })

            tl1.to('.newsletter__thanks-txt', .8, {
                opacity: 1
            })
        },
        thanksLeave(el,done) {
            const tl2 = new TimelineMax({
				onComplete: done
			})

            tl2.to('.newsletter__back', .1, {
                opacity: 0
            })
            tl2.to('.newsletter__thanks', .8, {
                opacity: 0
            })
        }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
  },
}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';

.newsletter_output_message {
	margin-top: 1rem;
	span {
		color: green;
	}
	& span.newsletterError {
		color: red;
	}
}

.newsletter {
    position: relative;
    min-height: 500px;
    width: 100%;
    padding:40px;
    // background-image: url('../../assets/img/background.png');
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    overflow: hidden;
    @include  tabletSize{
      min-height: 710px;
    }
    h2 {
        font-size: $large_heading;
        font-family: Financier;
        font-weight: 400;
        color: #FFF;
        text-align: center;
        margin-bottom: 20px;
        z-index: 1;
        @include tabletSize {
            font-size: $large_mobile;
        }
    }
    &__front {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__back {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $black_brown;
        position: absolute;
        padding: 20px 50px;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        text-align: center;
        transform: translateY(-100%);
        // transform-origin: 0% top;
        transition: transform .8s;
        h3 {
            color: $white;
            font-size: 23px;
            margin-bottom: 50px;
            text-transform: uppercase;
            font-weight: 300;
            @include mobile {
                padding-top: 50px;
                margin-bottom: 20px;

            }
        }
        input {
            border: 1px solid $gold_sand;
            min-width: 280px;
            font-size: $button-txt;
            padding: 10px;
            background-color: transparent;
            margin-bottom: 14px;
            outline: none;
            color: $white;
            border-radius: 0;
            -webkit-appearance: none;
        }
        input::placeholder {
            color: $white;
            font-size: $button-txt;
            letter-spacing: 1px;
        }
        input:nth-of-type(1) {
            margin-right: 8px;
        }
        input:nth-of-type(2) {
            margin-left: 8px;
        }
        p {
            margin-top: 57px;
            color: $text_color;
            @include mobile {
                padding-top: 30px;
            }
        }
        @include mobile {
            input {
                margin: 0 0 13px 0 !important;

            }
        }
    }
    &__inputs {
        @include mobile {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
        }
    }
    &__btn {
        @include button;
        z-index: 1;
        border-color: $white;
        color: $white;

        &:after {
            background-color: $white;
        }
        &:hover {
            color: $brown;
        }
    }
    &__button {
        @include button;
        z-index: 1;
        color: $white;
        border-color: $gold_sand;
        letter-spacing: 1px;
                appearance  :none;
        border: 0;
        &:after {
        background-color: $gold_sand;
        }
        &:hover {
            color: $white;
        }
    }
    &__close {
        background-color: transparent;
        border: none;
        position: absolute;
        outline: none;
        right: 50px;
        top: 50px;
        img{
            width: 30px;
        }
        z-index: 22;
        @include mobile {
            right: 20px;
            top: 20px;
        }
    }
    &__video{
      position: absolute;
      left: 0;
      top: 0;
      min-width: 100%;
      height:100%;
      object-fit: cover;
      object-position: center;
    }
    &__thanks {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;
        left: -100%;
        background-color: $black_brown;
        z-index: 20;
        opacity: 0;
        transition: all .6s;
        h2 {
            font-size: $big_heading;
            color: $gold_sand;
            opacity: 0;
            transition: opacity .6s .1s;
        }
    }
    &__thanks-btn {
        background-color: transparent;
        border: none;
        position: absolute;
        outline: none;
        right: 50px;
        top: 50px;
        z-index: 22;
        transition: opacity .6s;
        opacity:0;
        img{
            width: 30px;
        }
        @include mobile {
            right: 20px;
            top: 20px;
        }
    }
}

.shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

</style>
