<template v-if="apartment">
	<section class='apartment'>
			<div class="apartment__wrapper">
				<div class="apartment__overview">
					<p class='apartment__title'>OVERVIEW</p>
					<div class="apartment__overview-container">
						<div class="apartment__overview-box-wrapper">
							<div class="apartment__overview-box">
								<p class="apartment__overview-box-title">GUESTS</p>
								<p class="apartment__overview-box-number">{{apartment.guests | roundOut}}</p>
						</div>
							<div class="apartment__overview-box">
								<p class="apartment__overview-box-title">FLOORS</p>
								<p class="apartment__overview-box-number">{{apartment.floors | roundOut}}</p>
						</div>
							<div class="apartment__overview-box">
								<p class="apartment__overview-box-title">BEDROOMS</p>
								<p class="apartment__overview-box-number">{{apartment.bedrooms | roundOut}}</p>
						</div>
						</div>
						<div class="apartment__overview-box-wrapper">
							<div class="apartment__overview-box">
								<p class="apartment__overview-box-title">BATHROOMS</p>
								<p class="apartment__overview-box-number">{{apartment.bathrooms | roundOut}}</p>
						</div>
						  <div class="apartment__overview-box">
								<p class="apartment__overview-box-title">INTERIOR</p>
								<p class="apartment__overview-box-number">{{apartment.interior | roundOut}}<span>sqm</span></p>
						</div>
						  <div class="apartment__overview-box">
								<p class="apartment__overview-box-title">EXTERIOR</p>
								<p class="apartment__overview-box-number">{{apartment.exterior | roundOut}}<span>sqm</span></p>
						</div>
						</div>
					</div>
			</div>
				<div class="apartment__features">
					<p class="apartment__title">KEY FEATURES</p>
					<ul>
						<li v-for='(key, index) in apartment.key_features' :key='index'>{{key}}</li>
					</ul>
			</div>
				<div class="apartment__details-wrapper">
					<p class="apartment__title">DETAILS</p>
					<div class="apartment__details" v-html='apartment.details'></div>
				</div>
				<div class="apartment__background"></div>
			</div>
	</section>

</template>

<script>
export default {
	computed: {
		apartment(){
			return this.$store.getters.getVillaById(this.data.planyo_apartment_id)
		}
	},
	filters: {
		roundOut(number){
			let numberToRound = Number(number);
			if(numberToRound < 10 && numberToRound >= 0){
				return `0${numberToRound}`;
			}else{
				return numberToRound;
			}
		}
	}
}
</script>

<style lang="scss">
		@import '@/styles/framework/variables.scss';
		@import '@/styles/framework/mixins.scss';
		.apartment{
			position: relative;
			width: 100%;
			top: -30px;
			padding: 5% 12%;
			@include tabletSize{
					top: 0;
			}
			&__wrapper{
				max-width: 1108px;
				margin: 0 auto;
				text-align: center;
				@include tabletSize{
					padding: 10% 5%;
				}
			}
			&__overview-box-wrapper{
				display:flex;
				width: 50%;
				justify-content:space-around;
				@include tabletSize{
					width: 100%;
					padding: 40px 10px;
					&:last-child{
						border-top: 1px solid $gold_sand;
					}
				}
			}
			&__background{
				position: absolute;
				left: 10%;
				right: 0;
				bottom: 0;
				top:0;
				background-color: $beige;
				z-index: -1;
			  	@include breakpoint-max('tablet'){
					left: 0;
				}
			}
			&__overview{
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			&__overview-container{
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: center;
				padding: 40px 20px;
				border-top: 1px solid $gold_sand;
				  @include breakpoint-max('tablet'){
					flex-direction: column;
					padding: 0px;
				}
			}
			&__title{
				font-family: "Poppins";
				font-size: 2.3rem;
				line-height: 3.4rem;
				text-transform: uppercase;
				color: $gold_sand;
				margin-bottom: 10px;
				  @include breakpoint-max('tablet'){
					font-size: 1.6rem;
					line-height: 2.5rem;
				}
			}
			&__overview-box-number{
				font-family: 'Financier';
				font-weight: 500;
				font-size: 3.4rem;
				line-height: 3.9rem;
				color: $text_color;
				letter-spacing: 0.04em;
				span{
				font-family: 'Poppins';
				font-size: 1.7rem;
				}
			}
			&__overview-box-title{
				font-family: 'Poppins';
				font-size: 1.4rem;
				line-height: 1.7rem;
				color: $black_brown;
				text-transform: uppercase;
			}
			&__overview-box{
				  @include breakpoint-max('tablet'){
					margin: 0 10px;
				}
			}
			&__features{
				padding: 40px 0;
				border-top: 1px solid $gold_sand;
				ul{
					list-style: none;
				}
				li{
					font-family: 'Financier';
					font-weight: 500;
					font-size: 3.4rem;
					line-height: 5rem;
					color: $text_color;
					  @include breakpoint-max('tablet'){
						font-size: 2.6rem;
						line-height: 4.5rem;
					}
				}
			}
			&__details-wrapper{
				border-top: 1px solid $gold_sand;
				padding: 40px 0;
			}
			&__details{

				h3{
					font-family: 'Financier';
					font-size: 3.4rem;
					line-height: 5rem;
					color: $text_color;
					font-weight: 500;
					  @include breakpoint-max('tablet'){
						font-size: 2.6rem;
						line-height: 3.1rem;

					}
				}
				p{
					max-width:560px;
					color: $text_color;
					font-size: 1.7rem;
					margin: 0 auto;
					margin-bottom: 17px;

					  @include breakpoint-max('tablet'){
						font-size: 1.4rem;
						margin-bottom: 30px;

					}
				}
			}
		}
</style>
