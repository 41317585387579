<template>
	<div class="tags">
		<router-link v-for="tag in tags.slice(0,2)" :key="tag.slug" class="tags__item" :to="'/blog?tag=' + tag.slug">{{'#' + tag.name}} </router-link>
	</div>
</template>
<script>
	export default {
		props: {
			tags: Array
		}
	}
</script>
<style lang="scss">
@import '@/styles/framework/variables.scss';
.tags {
	height: 18px;
	&__item {
		color: gray;
		text-decoration: none;
		text-transform: uppercase;
		will-change: color;
		transition: color 300ms;
		&:hover {
			color: $brown;
		}
	}
}
</style>
