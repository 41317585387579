<template>
    <section class="privacy">
        <div class="privacy__background">
            <img src="@/assets/img/marble.png" alt="">
        </div>
        <div class="privacy__container">
			<div class="privacy__gray-bgc"></div>
            <div class="privacy__heading">
                <div class="privacy__heading-case">
					<back-button class="privacy__heading-btn"></back-button>
                    <div class="privacy__heading-box">
                        <h2 :class="{'changeColor' : showPrivacy}" @click="showPrivacy = true">PriVACY POLICY</h2>
                        <h2 :class="{'changeColor' : !showPrivacy}" @click="showPrivacy = false">TERMS & CONDITIONS</h2>
                    </div>
				</div>

            </div>
            <transition name="component-fade" mode="out-in">
                <div v-show="showPrivacy" v-html="data.privacy" class="privacy__box"></div>
            </transition>
            <transition name="component-fade" mode="out-in">
                <div v-show="!showPrivacy" v-html="data.terms" class="privacy__case"></div>
            </transition>
        </div>
    </section>
</template>

<script>
import PostTags from "@/components/partials/PostTags.vue";
import BackButton from "@/components/partials/BackButton.vue";
	export default {
        data() {
            return {
                showPrivacy: true
            }
        },
		components: {
			BackButton
		},
		computed: {
    		lang() {
    			return this.$store.getters.getLocale;
    		}
		}
	}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';

.blog_page_link {
	color: $brown;
	text-decoration: none;
	will-change: color;
	transition: color 200ms;
	&:hover {
		color: lighten($brown,15%);
	}
}
.privacy {
    padding: 160px 0 160px 80px;
    position: relative;
    @include tabletSize {
        padding: 0;
    }
    &__background {
        position: absolute;
        width: 100%;
        height: 20vh;
        top: 0;
        left: 0;
        z-index: -1;
        img {
            height: auto;
            width: 100%;
            object-fit: cover;
        }
        @include tabletSize {
            display: none;
        }
    }
    &__container {
		position: relative;
    }
    &__heading {
        padding: 83px 0 74px 0;
        display: flex;
        justify-content: center;
        font-size: $small_heading;
        color: $brown;
		h2 {
			font-weight: 400;
            font-size: $small_heading;
		}
        @include tabletSize {
            h2 {
                display: none;
            }
        }
    }
    &__heading-box {
        display: flex;
        text-transform: uppercase;
        h2 {
            color: #BDBDBD;
        }
        h2:nth-of-type(1) {
            margin-right: 40px;
        }
    }
    &__heading-case {
		display: flex;
		align-items: center;
        justify-content: center;
        a {
            font-size: $mobile_txt;
        }
        img {
            margin-bottom: 2px;
        }
	}
    &__heading-next {
        display: flex;
		align-items: center;
        justify-content: center;
        a {
            font-size: 17px;
            text-transform: uppercase;
            color: $black_brown;
        }
        img {
            margin-left: 1rem;
            transform: rotate(180deg);
        }
        @include tabletSize {
            display: none;
        }
    }
	&__heading-btn {
        position: absolute;
        left: 20%;
        font-size: 17px !important;
        color: $black_brown !important;
        @include tabletSize {
			display: none;
		}
	}
    &__gray-bgc {
    	position: absolute;
		top: 0;
		left: 0;
		min-height: 55vh;
		z-index: -1;
		width: 100%;
		background-color: $gray;
        @include breakpoint-max('big') {
			min-height: 45vh;
		}
		@include tabletSize {
			display: none;
		}
    }
    &__box, &__case {
    	position: relative;
        padding: 0 160px;
        height: 100%;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
		@include breakpoint-max('big') {
			padding: 0 120px;
		}
		@include tabletSize {
			padding: 0;
		}
    }
	&__img {
        display: flex;
        justify-content: center;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
	&__title {
		text-align: center;
		padding: 0 30px;
		margin: 30px 0 60px 0;
		span {
			color: $dark_gray;
			font-size: $mobile_txt;
		}
		h3 {
			color: $black_brown;
			font-size: $big_heading;
			font-family: Financier;
			font-weight: 400;
            @include tabletSize {
                font-size: $mobile_heading;
                margin-bottom: 10px;
            }
		}
        @include tabletSize {
            margin: 60px 0 38px 0;
        }
	}
	&__content {
        margin-bottom: 50px;
		p {
			color: $text_color;
			font-size: $small_txt;
            @include tabletSize {
                padding: 0 23px;
                font-size: $mobile_txt;
            }
		}
	}
}

.img-and-description {
    margin: 80px 0;
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    p {
        font-size: $mobile_txt;
    }
}

.full-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.big-txt {
    margin: 60px 0;
    padding: 0 20px;
    font-size: $big-txt !important;
    font-weight: 500 !important;
    text-align: center;
    color: $text_color;
}

.component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.changeColor {
    color: $brown !important;
    transition: color .5s ease-in-out;
}

</style>
