<template>
<section class="contact">
	<div class="contact__box">
		<div class="contact__background">
			<img :data-src="data.background"
			     alt="Background"
			     class="lozad">
		</div>
		<div class="contact__info">
			<h2>{{data.title}}</h2>
			<div class="contact__case">
				<h3>{{data.phone}}</h3>
				<span><a href="tel:+41433001070"
					   class="active_contact_link active_contact_link--without-decoration">+41 43 300 10 70</a></span>
			</div>
			<div class="contact__case">
				<h3>{{data.mail}}</h3>
				<span><a href="mailto:concierge@atriumgroup.ch"
					   class="active_contact_link active_contact_link--without-decoration">concierge@atriumgroup.ch</a></span>
			</div>
			<div class="contact__case">
				<h3>{{data.address}}</h3>
				<span>Dufourstrasse 65<br> 8702 Zollikon<br> Switzerland</span>
			</div>
		</div>
		<div class="contact__message">
			<div class="contact__message-case">
				<h2>{{data.second_title}}</h2>
				<form @submit.prevent="onSubmit">
					<div class="contact__inputs">
						<input type="text"
						       name=""
						       value=""
						       placeholder="Your name"
						       required
						       v-model="contactName">
						<input type="email"
						       name=""
						       value=""
						       placeholder="E-mail address"
						       required
						       v-model="contactEmail">
					</div>
					<div class="contact__text">
						<textarea required
						          placeholder="Your message..."
						          v-model="contactMessage"></textarea>
					</div>
					<div class="contact__control">
						<label class="checkbox-contact">
							<input type="checkbox"
							       name="agreement-1"
							       id="agreement-1"
							       required
							       v-model="checked">
							I agree to the Atrium Residences <router-link to="/terms-and-conditions"
							             class="active_contact_link">Privacy policy</router-link>
							<span><svg :class='{"showCross" : checked.length == 1 }'
								     width="16"
								     height="16"
								     viewBox="0 0 16 16"
								     fill="none"
								     xmlns="http://www.w3.org/2000/svg">
									<path d="M0.5 0.5L15.5 15.5M15.5 0.5L0.5 15.5"
									      stroke="#A66E5D" />
								</svg>
							</span>
						</label>
					</div>
					<vue-recaptcha ref="recaptcha"
					               @verify="onVerify"
					               @expired="onExpired"
					               size="invisible"
					               :sitekey="sitekey">
					</vue-recaptcha>
					<input type="submit"
					       class="contact__btn"
					       :value="submitText">
					<div class="contact_output_message">
						<span :class="{contactError: contactError}"
						      v-if="contactOutputMessage">{{contactOutputMessage}}</span>
					</div>
				</form>
			</div>
		</div>
	</div>
</section>
</template>

<script>
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
export default {
	components: {
		VueRecaptcha
	},
	data() {
		return {
			checked: [],
			contactName: "",
			contactEmail: "",
			contactMessage: "",
			contactError: false,
			contactOutputMessage: "",
			submitText: "Send message",
			sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
		}
	},
	methods: {
		onSubmit() {
			this.$refs.recaptcha.execute();
		},
		onVerify(recaptchaToken) {
			this.$refs.recaptcha.reset();
			this.recaptchaToken = recaptchaToken;

			this.sendMessage();
		},
		sendMessage() {
			this.submitText = "Sending..."
			axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/send', {
					name: this.contactName,
					email: this.contactEmail,
					message: this.contactMessage,
					recaptcha: this.recaptchaToken
				})
				.then(response => {
					if (response.data === "ok") {
						// this.contactOutputMessage = "Message sent.";
						this.$router.push('/thank-you-contact');

					} else {
						this.contactOutputMessage = response.data;
						this.contactError = true;
					}
					this.submitText = "Send message";
					this.resetOutputMessage();
				})
		},
		resetOutputMessage() {
			setTimeout(() => {
				this.contactOutputMessage = "";
				this.contactError = false;
			}, 4000);
		},
		onExpired() {
			console.error("Recaptcha expired!");
		}
	}
}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';

.contact_output_message {
    margin-top: 1rem;
    color: green;
    & span.contactError {
        color: red;
    }
}

.active_contact_link {
    &--without-decoration {
        text-decoration: none;
    }
    color: black;
    will-change: color;
    transition: color 200ms;
    &:hover {
        color: $brown;
    }
}
.contact {
    position: relative;
    @include tabletSize {
        padding-bottom: 100px;
    }
    &__box {
        min-height: 850px;
        margin: 160px 0 80px 80px;
        padding: 90px 160px;
        background-color: $beige;
        display: flex;
        @media(max-width: 1450px) {
            padding: 90px 100px;
        }
        @media(max-width: 1220px) {
            padding: 60px;
        }
        @include tabletSize {
            flex-direction: column;
            margin: 0;
            padding: 140px 0 0;
        }
    }
    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: -50%;
        z-index: -1;
        img {
            height: 100%;
            width: 100%;
            position: absolute;
            object-fit: cover;
        }
        @include tabletSize {
            display: none;
        }
    }
    &__info {
        flex: 1;
        h2 {
            font-size: $small_heading;
            color: $brown;
            font-weight: 400;
            margin-bottom: 26px;
            @include tabletSize {
                display: none;
            }
        }
    }
    &__case {
        margin-bottom: 36px;
        h3 {
            font-size: $mobile_txt;
            text-transform: uppercase;
            font-weight: 400;
        }
        span {
            font-size: $big_heading;
            font-family: Financier;
        }
        a,
        h3,
        span {
            color: $black_brown;
        }
        @include tabletSize {
            text-align: center;
            margin: 0;
            padding: 0 20px;
            span {
                font-size: $large_mobile;
            }
        }
    }
    &__case:nth-of-type(2) {
        @include tabletSize {
            margin: 76px 0;
        }
    }
    &__message {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 80px;
        @include tabletSize {
            align-items: center;
            background-color: #FFF;
            padding-top: 50px;
        }
    }
    &__message-case {
        width: 75%;
        text-align: center;
        h2 {
            text-align: left;
            font-size: $big_heading;
            color: $brown;
            font-weight: 400;
            font-family: Financier;
            @include tabletSize {
                font-size: $large_mobile;
                text-align: center;
            }
        }
    }
    &__inputs {
        display: flex;
        width: 100%;
        input {
            width: calc(51% - 8px);
            padding: 1rem;
            background: none;
            margin: 30px 0 16px;
            border: 1px solid $gold_sand;
            outline: none;
            font-size: 1.7rem;
            font-family: "Poppins", sans-serif;
            border-radius: 0;
            -webkit-appearance: none;
        }
        input:nth-of-type(1) {
            margin-right: 8px;
        }
        @include tabletSize {
            width: 100%;
            display: block;
            input {
                width: 100%;
                margin: 0 0 8px 0 !important;
            }
            input:nth-of-type(1) {
                margin-top: 30px !important;
            }
        }
    }
    &__text {
        height: 300px;
        textarea {
            font-family: "Poppins", sans-serif;
            border: 1px solid $gold_sand;
            padding: 1rem;
            width: 100%;
            background: none;
            height: 100%;
            resize: none;
            overflow: auto;
            border-radius: 0;
            appearance: none;
            box-sizing: border-box;
            outline: none;
            font-size: 1.7rem;
        }
    }
    &__control {
        margin: 20px 0;
        a {
            color: $text_color;
        }
    }
    &__btn {
        @include button;
        z-index: 1;
        appearance: none;
        border-radius: 0;
        -webkit-appearance: none;
        border: $gold_sand;
        background-color: $gold_sand;
        color: #FFF;
        font-family: "Poppins", sans-serif;
        &:after {
            background-color: $brown;
        }
        &:hover {
            color: $beige;
        }
    }
}
.checkbox-contact {
    position: relative;
    transition: color 0.6s;
    span {
        position: absolute;
        top: 2px;
        left: -30px;
        height: 16px;
        width: 16px;
        border: 1px solid $brown;
        background-color: transparent;
        @include breakpoint-max('xs-tablet') {
            top: 1px;
        }
        svg {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 15px;
            width: 15px;
            position: absolute;
            opacity: 0;
            transition: opacity 0.6s;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
    input:checked + span {
        color: #fff;
    }

}
.showCross {
    opacity: 1 !important;
}
.grecaptcha-badge{
    bottom: 0 !important;
    right: 0 !important;
}
</style>
