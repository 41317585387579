<template>
    <section class="page-not-found">
        <div class="page-not-found__box">
            <div class="page-not-found__content">
                <p>404</p>
                <p>Page not found</p>
                <p>The link you followed may be broken or the page may have<br> been removed. We are sorry for inconvenience.</p>
            </div>
            <div class="page-not-found__buttons">
                <router-link to="/" class="page-not-found__btn">HOME PAGE</router-link>
            </div>
        </div>
        <div class="page-not-found__contact">
            <div class="page-not-found__phone">
                <p>Call us at</p>
                <a href="tel:+41433001070">+41 43 300 10 70</a>
            </div>
            <div class="page-not-found__mail">
                <p>E-MAIL US</p>
                <a href="mailto:info@atriumgroup.ch">info@atriumgroup.ch</a>
            </div>
        </div>
        <!-- <div class="container">
            <div class="columns is-centered is-vcentered is-flex-mobile">
                <div class="column">
                    <h1 class="title has-text-centered">404<br />Page not found</h1>
                    <p class="has-text-centered">
                        Please go to <a href.prevent="#" @click="$router.push('/')">Home</a>
                    </p>
                </div>
            </div>
        </div> -->
    </section>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: 'Page not found',
            meta: [
                {
                    'name': 'robots',
                    'content': 'noindex, nofollow'
                }
            ]
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';
@import '@/styles/framework/buttons.scss';

.page-not-found {
    height: 100vh;
    background-color: $brown;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    &__logo{
        .logo-svg{
            max-width: 300px;
            height: 200px;
            .a{
              transition: all .8s;
            }
            @include tabletSize{
              top: -9px;
              max-width: 200px;

              .a{
                fill: transparent;
                stroke: #d1bea6;
                stroke-width: 5px;
                transition: all .8s;
              }
            }
            &:hover{
              .a{
                fill: #d1bea6;
              }
            }
        }
        @include tabletSize {
            margin-top: 40px;
        }
    }
    &__box {
        margin: 420px 0 300px 0;
        @include tabletSize {
            margin: 200px 0 150px 0;
        }
    }
    &__content {
        text-align: center;
        p:nth-of-type(1) {
            color: $gold_sand;
            font-size: $small_heading;
        }
        p:nth-of-type(2) {
            margin: 5px 0 10px 0;
            color: #FFF;
            font-size: $big_heading;
            font-weight: 500;
        }
        p:nth-of-type(3) {
            color: #FFF;
            font-size: $small_txt;
        }
    }
    &__buttons {
        display: flex;
        justify-content: center;
        a:nth-of-type(1) {
            background-color: $brown;
            border-color: #FFF;
            &:after {
                background-color: $brown;
            }
        }
        @include mobile {
            flex-direction: column;
            align-items: center;
            a {
                max-width: 280px;
            }
        }
    }
    &__btn {
        @include button;
        margin: 40px 7px 0 7px;
        z-index: 1;
        color: #FFF;
    }
    &__contact {
        margin-bottom: 80px;
        display: flex;
        text-align: center;
        font-size: $mobile_txt;
        a {
            color: #FFF;
            text-decoration: none;
        }
        @include breakpoint-max('mobile') {
            flex-direction: column;
        }
    }
    &__phone {
        margin-right: 20px;
        color: $gold_sand;
        p {
            text-transform: uppercase;
        }
        @include breakpoint-max('mobile') {
            margin: 0;
        }
    }
    &__mail {
        margin-left: 20px;
        color: $gold_sand;
        p {
            text-transform: uppercase;
        }
        @include breakpoint-max('mobile') {
            margin: 20px 0 0 0;
        }
    }
}

</style>
