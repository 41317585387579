<template>
  <section class='video_and_text full-container'>
    <img class='video_and_text__background-image lozad' :data-src="data.background_image.url" alt="background image">
      <div class="video_and_text__top-container">
          <p class='video_and_text__small-title'>{{data.small_title}}</p>
          <p class='video_and_text__text'>{{data.text}}</p>
      </div>
      <div class="video_and_text__bottom-container">
        <div class="video_and_text__video-wrapper">
			<lazy-video class="video_and_text__video-background" :bamSrc="data.video.url" :bamPoster="data.poster.url"></lazy-video>
          <div class="video_and_text__overlays">
              <div v-for='(overlay,index) in data.overlays' :key='index' class="video_and_text__overlay">
                <!-- <div class="video_and_text__overlay-front"> -->
                  <!-- {{overlay.title}} -->
                  <!-- <router-link :to="overlay.link.url" v-html="overlay.title" class="video_and_text__link"></router-link> -->
                <!-- </div> -->
                <div class="video_and_text__overlay-back" >
                  <router-link :to="overlay.link.url" v-html="overlay.title" class="video_and_text__title"></router-link>
                  <div v-html="overlay.content" class="video_and_text__overlay-back-content">
                  </div>
                  <router-link :to="overlay.link.url" v-html="overlay.link.title" class="video_and_text__link"></router-link>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="video_and_text__background">
      </div>
  </section>
</template>

<script>
export default {
    mounted() {
        // let video = this.$refs.video;
        // if (video) {
		// 	this.$bamUtils.videoObserver(video)
        // }
    }
}
</script>


<style lang='scss'>
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';
@import '@/styles/framework/buttons.scss';
  .video_and_text{
    margin: 5% 0;
    padding: 0 0 5%;
    position: relative;
    &__top-container{
      display: flex;
      justify-content: center;
      flex-direction:column;
      margin: 0 0 100px;
      padding: 90px 60px 0 ;
      @include tabletSize{
        padding: 90px 0 0 ;
        margin: 0 0 50px;
      }
    }
    &__small-title{
      text-align: center;
      font-size:2.3rem;
      font-family:"Poppins";
      font-weight: 300;
      color: $brown;
      margin-bottom: 20px;
      @include tabletSize{
        font-size:1.6em;
      }
    }
    &__background{
      position: absolute;
      right: 40px;
      left: 20px;
      bottom: 0;
      top: 0;
      z-index: -1;
      background-color:$beige;
      @include tabletSize{
        right: 0;
        left: 0;
        bottom: 15%;
      }
    }
    &__text{
      max-width: 1280px;
      font-size:$big_heading;
      font-family: 'Financier';
      text-align: center;
      margin: 0 auto;
      font-weight: 500;
      @include tabletSize{
        font-size:2.6rem;
        padding: 0 20px;
      }
    }
    &__background-image{
      position: absolute;
      right: 0;
      top: -20px;
      z-index: -2;
      @include tabletSize{
        right: auto;
        left: 0;
        top: -40px;
        width: 80%;

      }
    }
    &__bottom-container{
      max-width:1360px;
      max-height: 680px;
      display: flex;
      margin: 0 auto;
      padding: 0 60px;
      height: 100vh;
      position:relative;
      z-index: 3;
      @include tabletSize{
        padding: 0;
        max-height: 430px;

      }
    }
    &__video-wrapper{
      width: 100%;
      height: 100%;
      position:relative;
      overflow: hidden;
    }
    &__video-background{
      min-height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
    }
    &__overlays{
      position:absolute;
      z-index: 1;
      top: 0;
      left:0;
      width: 100%;
      height: 100%;
      display: flex;
      @include tabletSize{
        flex-direction: column;
        width: 100%;

      }
    }
    &__overlay{
      // &:hover .video_and_text__overlay-front{
      //   transform: translateY(-100%);
      // }
      // &:hover .video_and_text__overlay-back{
      //   transform: translateY(-100%);
      // }
      width: 34%;
      @include tabletSize{
        width: 100%;
        height:34%;
        overflow: hidden;
        &:hover .video_and_text__overlay-front{
          transform: translateY(0%);
        }
        &:hover .video_and_text__overlay-back{
          transform: translateY(0%);
        }
      }
    }
    // &__overlay-front{
    //   height:100%;
    //   background-blend-mode: multiply;
    //   color: $white;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   font-size: $big_heading;
    //   font-family: "Financier";
    //   text-align: center;
    //   transform: translateY(0%);
    //   transition: transform .6s;
    
    //   @include tabletSize{
    //     font-size:2.4rem;
    //     transition: background-color .6s;
    //     &:hover{
    //       background: rgba(46, 46, 51, 0.2);
    //     }
    //   }
    // }
    &__overlay-back{
      transform: translateY(0%);
      color: $white;
      height:100%;
      flex-direction: column;
      display: flex;
      transition: transform .6s;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      z-index: 1;
      padding: 0 5%;
      &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(46, 46, 51, 0.8);
        z-index: -1;
        opacity: 0;
        transition: opacity .6s;
      }
      &:hover::after{
        opacity: 1;
      }
      &:hover .video_and_text__title{ 
        transform: translateY(0px);

      }
      &:hover .video_and_text__overlay-back-content p{ 
        opacity: 1;
        transform: translateY(0px);

      }
      &:hover .video_and_text__link{ 
        opacity: 1;
        transform: translateY(0px);

      }
      @include tabletSize{
            &:hover::after{
        opacity: 0;
      }
      &:hover .video_and_text__title{ 
        transform: translateY(0px);

      }
      &:hover .video_and_text__overlay-back-content p{ 
        opacity: 0;
        transform: translateY(70px);

      }
      &:hover .video_and_text__link{ 
        opacity: 0;
        transform: translateY(90px);

      }
      }
    }
    &__title{
        font-size: $big_heading;
        font-family: "Financier";
        border: none;
        margin: 0;
        color: $white;
        transition: transform .6s;
        transform: translateY(120px);
        text-decoration: none;
        text-transform: capitalize;

        &:hover{
          &:after{
            content: none;
            transform: scaleX(0);
          }
        }
        @include tabletSize{
          font-size:2.8rem;
        transform: translateY(0px);
        }
    }
    &__overlay-back-content{
      font-family: "Poppins";
      display: flex;
      justify-content: center;
      min-height: 200px;
      align-items: center;
      @include tabletSize{
        display:none;
        }
      p{
        font-size: $small_txt;
        opacity: 0;
        transition: opacity .8s,transform .6s;
        transform: translateY(70px);

      }
    }
    // &__overlay-back-title{
    //   font-size:$big_heading;
    //   font-family: 'Financier';
    //   margin-bottom: 20px;
    //   padding: 0 20px;
    // }
    &__link{
      @include button;
      border-color: $white;
      color: $white;
      margin-top: $button_margin;
      opacity: 0;
      transition: opacity .6s,transform .6s;
      transform: translateY(90px);
      &:hover {
          color: $gold_sand;
      }
      &:after {
          background-color:#FFF;
      }
      @include tabletSize{
        display:none;
        }
    }
  }
  .video_and_text__overlay:nth-child(even){
    border-left: 1px solid $white;
    border-right: 1px solid $white;
    @include tabletSize{
      border-left: none;
      border-right: none;
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
    }

  }

</style>
