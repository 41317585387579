<template>
    <div id="app">
        <!-- <custom-cursor/> -->
        <the-header />
        <main class="scroll-content">
            <transition name="component-fade" mode="out-in">
                <router-view :key="$route.params.id" />
            </transition>
            <newsletter />
            <partners />
            <the-footer />
        </main>
    </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
// import CustomCursor from '@/components/CustomCursor.vue'
import Partners from "@/components/partials/global/Partners.vue";
import Newsletter from "@/components/partials/global/Newsletter.vue";

export default {
    components: {
        "the-header": TheHeader,
        "the-footer": TheFooter,
        // 'custom-cursor': CustomCursor,
        partners: Partners,
        newsletter: Newsletter,
    },
    metaInfo() {
        return {
            titleTemplate: this.seoTitleTemplate,
            title: this.title,
            htmlAttrs: {
                lang: this.$store.getters.getLocale,
            },
            meta: [
                {
                    name: "description",
                    property: "og:description",
                    content: this.seoDescription,
                },
                { name: "robots", content: this.seoRobots },
                {
                    name: "image",
                    property: "og:image",
                    content: require("@/assets/img/atrium-og-image.png"),
                },
                { name: "twitter:card", content: "summary" },
                { name: "twitter:title", content: this.title },
                {
                    name: "twitter:image",
                    content:
                        "https://atriumresidences.ch" +
                        require("@/assets/img/atrium-og-image-twitter.png"),
                },
            ],
            link: [{ rel: "icon", href: this.optionsFavicon }],
        };
    },
    computed: {
        page() {
            let page = this.$store.getters.getPageByPath(this.$route.path);
            if (page === undefined) {
                this.$router.push({ name: "error404" });
            } else {
                return this.$store.getters.getPageByPath(this.$route.path);
            }
        },
        title() {
            if (this.page !== undefined) {
                return (
                    this.page.title
                        .replace("&#038;", "")
                        .replace("&#8217;", "'") + " | Atrium Residences"
                );
            } else {
                return "";
            }
        },
        seoTitleTemplate() {
            return this.page.seo_title != null
                ? this.page.seo_title
                : this.$store.getters.getSeoTitleTemplate;
        },
        seoDescription() {
            return this.page.seo_description != null
                ? this.page.seo_description
                : "";
        },
        seoRobots() {
            return this.page.seo_no_index == true
                ? "noindex,nofollow"
                : "index,follow";
        },
        optionsFavicon() {
            return this.$store.getters.getOptionsFavicon;
        },
    },
    mounted() {
        this.$bamUtils.scroll = this.$bamUtils.scrollbar.init(
            document.querySelector(".scroll-content")
        );
    },
    watch: {
        $route(to, from) {
            this.$bamUtils.scroll.scrollTop = 0;

            if (location.hash) {
                setTimeout(() => {
                    let el = document.querySelector(location.hash);
                    this.$bamUtils.scroll.scrollIntoView(el);
                }, 1000);
            }
        },
    },
};
</script>

<style lang="scss">
.scroll-content {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media (min-width: 1082px) {
        width: calc(100% - 32px);
    }
}

.component-fade-enter-active,
.component-fade-leave-active {
    transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
