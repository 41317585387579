<template>
<video :poster="bamPoster" :data-src="bamSrc" type="video/mp4" preload="none" loop playsinline muted ref="lazyVideo"></video>
</template>
<script>
export default {
    props: {
        bamSrc: String,
        bamPoster: String
    },
    methods: {
        playTheVideo() {
            const lazyVideo = this.$refs.lazyVideo;
            lazyVideo.src = lazyVideo.dataset.src;
            lazyVideo.play();
        }
    },
    mounted() {
        const lazyVideo = this.$refs.lazyVideo;

        if ("IntersectionObserver" in window && this.bamPoster) {
            let lazyVideoObserver = new IntersectionObserver(entries => {
                entries.forEach(video => {
                    if (video.isIntersecting) {
                        this.playTheVideo();
						lazyVideoObserver.unobserve(lazyVideo);
                    }
                });
            });
            lazyVideoObserver.observe(lazyVideo);
        } else {
            // If browser doesn't support intersection observer or if there isn't poster image, just play the video
            this.playTheVideo();
        }
    }
}
</script>
