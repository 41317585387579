<template>
    <section class="thank-you">
        <!-- <div class="thank-you__logo" @click="navigateTo('/')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 620" class="logo-svg">
                <defs>
                    <svg:style>.a{fill:transparent;stroke: #d1bea6; stroke-width:5px; }.b{fill:#a66e5d;}.c{fill:#2E2E33;}</svg:style>
                </defs>
                <path class="a" d="M540.16,250.36a60.91,60.91,0,1,1,60.9-60.91,61,61,0,0,1-60.9,60.91M643.6,189.45a103.58,103.58,0,1,0-42.26,83.34l.06,18h42.2V189.46h0"/>

                <g class="logotype">
                    <path class="c" d="M117.94,371.15l-12.88,35.14h26.21Zm-10.51,67.91H83.7v-1.47c4.3-.34,7.46-1.13,9.27-6l23.73-63.84-3.28-8.59h17.52l27.9,74c1.25,3.28,4,4.18,7.69,4.41v1.47H131.27v-1.47c6.44-.23,9.27-1.47,9.27-5a16.77,16.77,0,0,0-1-4.41l-7.45-19.66h-27.8L97.15,428.1a13.14,13.14,0,0,0-.79,4.18c0,3.62,3.73,5.08,11.07,5.31Z"/>
                    <path class="c" d="M188.11,380.3h15v4h-15v37.17c0,7.23,3.16,10.4,8.93,10.4a14.91,14.91,0,0,0,8.47-2.6l.34.45c-3.39,5.31-9.49,10.51-17.52,10.51-9.37,0-15.93-5.31-15.93-17.51V384.26h-7v-1.13c7.57-3.5,17.06-11,22-18.31h.68Z"/>
                    <path class="c" d="M250.26,378a8.55,8.55,0,0,1,4.86,1.47v13H253c-7.12,0-13.56.56-18.31,5.31v34.8c0,4.07,2.72,4.52,9.72,5.08v1.36H211.61V437.7c5.31-.34,7.35-.79,7.35-4.86V389.68l-7-5v-.45L234.66,378h.46l-.46,18.65c3.85-11.42,8.25-18.65,15.6-18.65"/>
                    <path class="c" d="M268.22,362.34a9.16,9.16,0,0,1,18.31,0,9.16,9.16,0,0,1-18.31,0m17.18,70.5c0,4.07,2,4.52,7.23,4.86v1.36H262.35V437.7c5.31-.34,7.34-.79,7.34-4.86V389.68l-7-5v-.45L285,378h.45Z"/><path class="c" d="M357.38,429.34c0,4.07.57,5.31,7.46,5.65v1.13l-23,4.07h-.45l.33-11.07c-4.29,5.42-11.29,11.07-19.66,11.07-9.6,0-16.27-5.76-16.27-18.3V390.13c0-4.06-.9-5.53-7-6.66v-.91l22.82-4.4H322l-.56,13.44v28.93c0,7.23,2.82,11.07,9.6,11.07a16.91,16.91,0,0,0,10.62-3.5v-38c0-4.06-.79-5.53-6.89-6.66v-.91l22.72-4.4H358l-.57,13.22Z"/>
                    <path class="c" d="M473.32,437.7v1.36H443.37V437.7c5.2-.34,7-.79,7-4.85V397.48c0-7-2.49-10.51-8.59-10.51a19.66,19.66,0,0,0-11.19,3.84v42c0,4.06,1.81,4.51,7,4.85v1.36H407.89V437.7c5.2-.34,7-.79,7-4.85V397.48c0-7-2.49-10.51-8.59-10.51a19.66,19.66,0,0,0-11.19,3.84v42c0,4.06,1.81,4.51,7,4.85v1.36H372.07V437.7c5.32-.34,7.35-.79,7.35-4.85V389.68l-7-5v-.45L395.12,378h.46l-.46,11.87c3.85-5.2,11.19-11.87,20-11.87,8.24,0,13.67,4.3,15.36,12,3.84-5.31,11.3-12,20.11-12,10.29,0,15.49,6.67,15.49,18.42v36.39c0,4.06,2,4.51,7.23,4.85"/>
                    <path class="c" d="M513,361.55l-8.25,38.64H511c14.69,0,24.74-7.8,24.74-22.37,0-9-4.52-16.27-15.48-16.27Zm-14.69,68.36c-1.47,6.89.79,7.12,8.25,8l-.22,1.13H475.81l.22-1.13c8.59-1,10.29-1.58,11.64-8l13.11-61c1.47-6.89-.79-7.12-8.25-8l.23-1.13H523c18,0,24.07,8.92,24.07,17.85,0,13.79-13.11,20.57-24.52,23.28l10.5,20.22c6.45,12.55,11.08,16.61,17.29,16.84l-.22,1.13h-18c-2.94-2.94-6.33-8.47-9.94-15.82l-10.28-21h-7.69Z"/>
                    <path class="c" d="M562.64,410.81c17.29-3.95,26.1-10.85,26.1-22.6,0-3.84-1.47-7.23-5.87-7.23-9.38,0-18.08,15.14-20.23,29.83M570,440.19c-11.3,0-17.74-8.25-17.74-20.34,0-18.64,15.59-40.68,32.54-40.68,8.93,0,12.88,5,12.88,11,0,13.44-18.42,18.86-35.25,21.91a46.68,46.68,0,0,0-.34,5.65c0,10.62,4.4,16.61,12,16.61,6.66,0,12.42-4.06,17.51-11.3l.79.46c-5.2,9.83-13.22,16.72-22.37,16.72"/>
                    <path class="c" d="M630.58,427.08c0-10.84-20.56-17.74-20.56-30.39,0-7.57,8.36-17.52,19.77-17.52,7.8,0,12.77,4.07,12.77,9.61,0,3.16-3,5.53-5.65,6.55h-.22c-.91-7.23-6-13.11-12.43-13.11a7.78,7.78,0,0,0-8,8c0,9.83,20.79,18.08,20.79,30.05,0,9.72-11.07,19.89-22,19.89-8.93,0-14.69-5.88-14.69-11.86a8.94,8.94,0,0,1,5.65-7.8h.23c1.24,8.7,7.46,16.16,14.57,16.16a9.65,9.65,0,0,0,9.83-9.61"/>
                    <path class="c" d="M665.47,363.47a7.25,7.25,0,0,1,7.12-7.23,6.48,6.48,0,0,1,6.55,6.55A7.26,7.26,0,0,1,672,370a6.48,6.48,0,0,1-6.55-6.55m-4.41,25.42-7.68-4.41V384l19.54-4.86h.46l-12,47.46a23.42,23.42,0,0,0-.9,4.86c0,1.36.45,2,1.69,2,2.37,0,7-3.95,11.3-10l.68.45c-5.31,9.49-12,16.27-18.31,16.27-3.5,0-5.53-2.26-5.53-6a30,30,0,0,1,1.13-7.12Z"/>
                    <path class="c" d="M726,389.91c-.22-4.52-1.8-9-7.34-9-13.56,0-26.55,21.58-26.55,41.47,0,7.57,2,11,6.21,11,9.38,0,23.17-17.4,27.68-43.39m-4.63,50.28c-3.5,0-5.53-2.26-5.53-6a30.36,30.36,0,0,1,1.12-7.12l3.85-15.59c-7.12,16.16-18.19,28.7-26.78,28.7-7,0-11.75-6.33-11.75-16.84,0-22.82,19.09-44.18,36.38-44.18a15.24,15.24,0,0,1,9.38,3.17l4.37-17.91-8.7-4.07v-.45L744,355.05h.46l-17.59,71.58a24.46,24.46,0,0,0-.91,4.86c0,1.36.46,2,1.7,2,2.37,0,7-3.95,11.3-10l.68.45c-5.31,9.49-12,16.27-18.31,16.27"/>
                    <path class="c" d="M759,410.81c17.29-3.95,26.1-10.85,26.1-22.6,0-3.84-1.47-7.23-5.87-7.23-9.38,0-18.08,15.14-20.23,29.83m7.34,29.38c-11.29,0-17.74-8.25-17.74-20.34,0-18.64,15.6-40.68,32.55-40.68,8.92,0,12.88,5,12.88,11,0,13.44-18.42,18.86-35.26,21.91a48.8,48.8,0,0,0-.34,5.65c0,10.62,4.41,16.61,12,16.61,6.67,0,12.43-4.06,17.52-11.3l.79.46c-5.2,9.83-13.22,16.72-22.38,16.72"/>
                    <path class="c" d="M814.74,412.51c5.64-13.56,19.43-33.34,31.18-33.34,5.54,0,7.23,3.05,7.23,8a25,25,0,0,1-.68,5l-9.15,34.46a24.25,24.25,0,0,0-.9,4.86c0,1.36.45,2,1.69,2,2.38,0,7-3.95,11.3-10l.68.45c-5.31,9.49-12,16.27-18.31,16.27-3.5,0-5.53-2.26-5.53-6a30,30,0,0,1,1.13-7.12l9.38-35.36a18.62,18.62,0,0,0,.56-3.73c0-1.58-.56-2.38-2-2.38-7,0-23.84,16.39-28.93,37.07l-4.07,16.38h-9.83L811,388.89l-7.69-4.41V384l19.55-4.86h.45Z"/>
                    <path class="c" d="M871.77,417.7c0,10.62,4,16.61,11.41,16.61,6.55,0,12.09-4.52,17.18-11.75l.67.46c-4.74,9.83-12.65,17.17-21.69,17.17-10.62,0-17.4-8.25-17.4-20.34,0-19.21,15.82-40.68,32.88-40.68,7.45,0,11.86,3.39,11.86,8.93,0,4.41-4.07,7.34-7.91,8h-.22c.45-7.34-.23-14.69-6.78-14.69-10.74,0-20,19-20,36.27"/>
                    <path class="c" d="M919,410.81c17.29-3.95,26.1-10.85,26.1-22.6,0-3.84-1.47-7.23-5.87-7.23-9.38,0-18.08,15.14-20.23,29.83m7.34,29.38c-11.29,0-17.74-8.25-17.74-20.34,0-18.64,15.6-40.68,32.55-40.68,8.92,0,12.88,5,12.88,11,0,13.44-18.42,18.86-35.26,21.91a48.8,48.8,0,0,0-.34,5.65c0,10.62,4.41,16.61,12,16.61,6.67,0,12.43-4.06,17.52-11.3l.78.46c-5.19,9.83-13.21,16.72-22.37,16.72"/>
                    <path class="c" d="M984.32,427.08c0-10.84-20.57-17.74-20.57-30.39,0-7.57,8.37-17.52,19.78-17.52,7.8,0,12.77,4.07,12.77,9.61,0,3.16-3,5.53-5.65,6.55h-.23c-.9-7.23-6-13.11-12.43-13.11a7.78,7.78,0,0,0-8,8c0,9.83,20.79,18.08,20.79,30.05,0,9.72-11.07,19.89-22,19.89-8.93,0-14.69-5.88-14.69-11.86a8.92,8.92,0,0,1,5.65-7.8h.22c1.25,8.7,7.46,16.16,14.58,16.16a9.65,9.65,0,0,0,9.83-9.61"/>
                </g>

                <g class="tagline">
                    <path class="b" d="M161.24,524.06l2.36-.3c.3,4.54,3.25,8.26,9.5,8.26,4.75,0,8.08-2.27,8.08-6.38,0-4.4-2.69-5.69-6.76-6.5l-4.53-.86c-4.45-.85-7.36-3.29-7.36-7.78-.05-5,3.8-8.35,10-8.35,5.78,0,10.27,2.61,10.61,9l-2.31.38c-.39-5.21-3.38-7.48-8.39-7.48-4.66,0-7.53,2.44-7.49,6.24,0,3.13,1.85,5.18,5.91,5.95l4.58.86c4.58.9,8.13,2.87,8.13,8.26S179.51,534,173,534c-7.49,0-11.39-4.24-11.73-9.93"/>
                    <path class="b" d="M198.05,502.88l6.68,26.4a7.73,7.73,0,0,1,.25,1.45h.09c0-.21.13-.72.3-1.45l7.32-26.4h2.48l7.31,26.4a6.14,6.14,0,0,1,.3,1.45h.09c0-.21.09-.77.26-1.45l6.67-26.4h2.44l-8,30.38h-2.91l-7.14-26a8.49,8.49,0,0,1-.26-1.32h-.09a8.38,8.38,0,0,1-.25,1.32l-7.15,26h-2.91l-8-30.38Z"/>
                    <rect class="b" x="246.63" y="502.88" width="2.35" height="30.38"/>
                    <path class="b" d="M264.22,524.06l2.36-.3c.3,4.54,3.25,8.26,9.5,8.26,4.75,0,8.08-2.27,8.08-6.38,0-4.4-2.69-5.69-6.76-6.5l-4.53-.86c-4.45-.85-7.36-3.29-7.36-7.78-.05-5,3.8-8.35,10-8.35,5.78,0,10.27,2.61,10.61,9l-2.31.38c-.39-5.21-3.38-7.48-8.39-7.48-4.66,0-7.53,2.44-7.49,6.24,0,3.13,1.85,5.18,5.91,5.95l4.58.86c4.58.9,8.13,2.87,8.13,8.26S282.49,534,276,534c-7.49,0-11.39-4.24-11.73-9.93"/>
                    <path class="b" d="M299.49,524.06l2.35-.3c.3,4.54,3.26,8.26,9.5,8.26,4.75,0,8.09-2.27,8.09-6.38,0-4.4-2.69-5.69-6.76-6.5l-4.54-.86c-4.45-.85-7.36-3.29-7.36-7.78,0-5,3.81-8.35,10-8.35,5.77,0,10.27,2.61,10.61,9l-2.31.38c-.39-5.21-3.38-7.48-8.39-7.48-4.66,0-7.53,2.44-7.49,6.24,0,3.13,1.84,5.18,5.91,5.95l4.58.86c4.58.9,8.13,2.87,8.13,8.26s-4.07,8.6-10.61,8.6c-7.49,0-11.39-4.24-11.73-9.93"/>
                    <polygon class="b" points="357.7 502.88 357.7 533.26 374.94 533.26 374.94 531.25 360.05 531.25 360.05 502.88 357.7 502.88"/>
                    <path class="b" d="M391,522.78c0,5.56,3,9.11,8.6,9.11s8.69-3.55,8.69-9.11v-19.9h2.35v20c0,6.67-3.93,11.08-11,11.08s-10.95-4.41-10.95-11.08v-20H391Z"/>
                    <path class="b" d="M425,502.88h2.79l7.48,11a11.49,11.49,0,0,1,1.2,2h.09a12.88,12.88,0,0,1,1.2-2l7.44-11h2.65l-10,14.68,10.92,15.7H446L438,521.62a19.84,19.84,0,0,1-1.63-2.57h-.09a28,28,0,0,1-1.62,2.57l-7.92,11.64h-2.69L435,517.34Z"/>
                    <path class="b" d="M464.53,522.78c0,5.56,3,9.11,8.6,9.11s8.69-3.55,8.69-9.11v-19.9h2.35v20c0,6.67-3.93,11.08-11,11.08s-10.95-4.41-10.95-11.08v-20h2.35Z"/>
                    <path class="b" d="M511.82,517.81c5.27,0,7.28-2.86,7.28-6.5s-1.84-6.42-7.28-6.42h-7.91v12.92Zm-7.91,2v13.44h-2.35V502.88H512c7.57,0,9.58,4.24,9.58,8.43,0,3.55-1.75,7.31-7.14,8.21l8.08,13.74h-2.69l-7.92-13.44Z"/>
                    <path class="b" d="M533.79,502.88h2.65l8,13.52c.47.86.9,1.63,1.28,2.4h.09a22.89,22.89,0,0,1,1.28-2.4l8-13.52h2.61l-10.7,18.06v12.32h-2.39V520.94Z"/>
                    <polygon class="b" points="591.31 502.88 591.31 533.26 609.75 533.26 609.75 531.25 593.66 531.25 593.66 518.71 608.73 518.71 608.73 516.66 593.66 516.66 593.66 504.89 609.45 504.89 609.45 502.88 591.31 502.88"/>
                    <path class="b" d="M622.85,502.88h2.79l7.48,11a11.49,11.49,0,0,1,1.2,2h.09a12.88,12.88,0,0,1,1.2-2l7.44-11h2.65l-10,14.68,10.92,15.7h-2.83l-7.91-11.64a19.84,19.84,0,0,1-1.63-2.57h-.08c-.48.86-1,1.71-1.63,2.57l-7.92,11.64H622l10.91-15.92Z"/>
                    <path class="b" d="M678.15,511.18c0-3.85-2.83-6.29-7.27-6.29H663v13.05h7.84c4.44,0,7.27-2.61,7.27-6.76m2.48,0c0,5.39-3.72,8.77-9.67,8.77H663v13.31h-2.35V502.88H671c5.95,0,9.67,3.21,9.67,8.3"/>
                    <polygon class="b" points="695.02 502.88 695.02 533.26 713.46 533.26 713.46 531.25 697.37 531.25 697.37 518.71 712.43 518.71 712.43 516.66 697.37 516.66 697.37 504.89 713.16 504.89 713.16 502.88 695.02 502.88"/>
                    <path class="b" d="M739.91,517.81c5.27,0,7.28-2.86,7.28-6.5s-1.84-6.42-7.28-6.42H732v12.92Zm-7.91,2v13.44h-2.36V502.88h10.44c7.58,0,9.59,4.24,9.59,8.43,0,3.55-1.75,7.31-7.15,8.21l8.09,13.74h-2.69L740,519.82Z"/>
                    <rect class="b" x="766.41" y="502.88" width="2.35" height="30.38"/>
                    <polygon class="b" points="786.78 502.88 786.78 533.26 805.23 533.26 805.23 531.25 789.14 531.25 789.14 518.71 804.2 518.71 804.2 516.66 789.14 516.66 789.14 504.89 804.93 504.89 804.93 502.88 786.78 502.88"/>
                    <path class="b" d="M824.24,502.88l15.7,24.56c.64,1.11,1.16,2,1.67,3h0c0-1-.08-2-.08-3.38V502.88h2.18v30.38h-2.83l-15.7-24.69a24.34,24.34,0,0,1-1.67-3h-.08c.08,1,.08,2,.08,3.38v24.35h-2.14V502.88Z"/>
                    <path class="b" d="M859.42,518c0-9.37,4.75-15.83,13.48-15.83,7.14,0,11,4.49,11.81,10.74l-2.4.22c-.68-5.4-4-8.91-9.54-8.91-6.89,0-10.91,5.53-10.91,13.74,0,8,3.64,13.95,10.95,13.95,5.18,0,8.6-3.3,9.63-8.6l2.27.34c-1.11,5.74-4.62,10.36-11.94,10.36-9.29,0-13.35-7.19-13.35-16"/>
                    <polygon class="b" points="900.63 502.88 900.63 533.26 919.08 533.26 919.08 531.25 902.99 531.25 902.99 518.71 918.05 518.71 918.05 516.66 902.99 516.66 902.99 504.89 918.78 504.89 918.78 502.88 900.63 502.88"/>
                </g>
            </svg>
        </div> -->
        <div class="thank-you__box">
            <div class="thank-you__content">
                <p>{{data.text_one}}</p>
                <p>{{data.text_two}}</p>
                <p v-html="data.text_three"></p>
            </div>
            <div class="thank-you__buttons">
                <router-link to="/" class="thank-you__btn">HOME PAGE</router-link>
                <template v-if="data.show_leisure">
                <a  v-if="data.pdf" :href="data.pdf.url" class="thank-you__btn" target="_blank" :download='data.pdf.url'>LEISURE ACTIVITY BOOK</a>
                </template>
            </div>
        </div>
        <div class="thank-you__contact">
            <div class="thank-you__phone">
                <p>{{data.phone}}</p>
                <a href="tel:+41173030202">+41 79 754 32 14</a>
            </div>
            <div class="thank-you__mail">
                <p>{{data.mail}}</p>
                <a href="mailto:info@atriumgroup.ch">info@atriumgroup.ch</a>
            </div>
        </div>
    </section>
</template>

<script>
export default{

}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';
@import '@/styles/framework/buttons.scss';

.thank-you {
    background-color: $beige;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    height: 100vh;
    &__logo{
        .logo-svg{
            max-width: 300px;
            height: 200px;
            .a{
              transition: all .8s;
            }
            @include tabletSize{
              top: -9px;
              max-width: 200px;

              .a{
                fill: transparent;
                stroke: #d1bea6;
                stroke-width: 5px;
                transition: all .8s;
              }
            }
            &:hover{
              .a{
                fill: #d1bea6;
              }
            }
        }
        @include tabletSize {
            margin-top: 40px;
        }
    }
    &__box {
        margin: 420px 0 300px 0;
        @include tabletSize {
            margin: 200px 0 150px 0;
        }
    }
    &__content {
        text-align: center;
        p:nth-of-type(1) {
            color: $brown;
            font-size: $small_heading;
        }
        p:nth-of-type(2) {
            margin: 5px 0 10px 0;
            color: $black_brown;
            font-size: $big_heading;
            font-weight: 500;
        }
        p:nth-of-type(3) {
            color: $text_color;
            font-size: $small_txt;
        }
    }
    &__buttons {
        display: flex;
        justify-content: center;
        a:nth-of-type(1) {
            background-color: $text_color;
            border-color: $text_color;
            &:after {
                background-color: $text_color;
            }
        }
        a:nth-of-type(2) {
            background-color: $gold_sand;
            border-color: $gold_sand;
            &:after {
                background-color: $gold_sand;
            }
            @include mobile {
                margin-top: 20px;
            }
        }
        @include mobile {
            flex-direction: column;
            align-items: center;
            a {
                max-width: 280px;
            }
        }
    }
    &__btn {
        @include button;
        margin: 40px 7px 0 7px;
        z-index: 1;
        color: #FFF;
    }
    &__contact {
        margin-bottom: 80px;
        display: flex;
        text-align: center;
        font-size: $mobile_txt;
        a {
            color: $brown;
            text-decoration: none;
        }
        @include breakpoint-max('mobile') {
            flex-direction: column;
        }
    }
    &__phone {
        margin-right: 20px;
        color: $black_brown;
        p {
            text-transform: uppercase;
        }
        @include breakpoint-max('mobile') {
            margin: 0;
        }
    }
    &__mail {
        margin-left: 20px;
        color: $black_brown;
        p {
            text-transform: uppercase;
        }
        @include breakpoint-max('mobile') {
            margin: 20px 0 0 0;
        }
    }
}

</style>
