<template>
    <!-- <section class="articles">
        <div class="container">
            <div class="gray-square" :class="data.custom_class"></div>
            <div class="articles__case">
                <div class="articles__heading">
                    <h2>{{data.text}}</h2>
                    <router-link :to="'/blog'" class="all_articles">{{data.button}}</router-link>
                </div>
                <div class="articles__box">
                    <div class="articles__item"
                    v-for="(item,key) in posts"
    			     :key="key">
						<router-link :to="item.link">
                            <div class="articles__img">
                                <img :data-src="item.thumbnail" :alt="item.title" class="lozad">
                            </div>
						</router-link>
                        <router-link :to="item.link" class="articles__title"><h3 v-html="item.title"></h3></router-link>
                        <span class="articles__info">{{item.date}} by {{item.author ? item.author : "Atrium team" | removeSpacesAndAddDot}}</span>
                        <post-tags :tags="item.tags"></post-tags>
                    </div>
                </div>
            </div>
            <div class="articles__slider-box">
                <div class="articles__heading-mobile">
                    <h2>{{data.text}}</h2>
                    <router-link :to="'/blog'" class="all_articles">{{data.button}}</router-link>
                </div>
                <div class="articles__mobile">
                    <carousel
                    :loop="true"
                    :autoplay="true"
                    :autoplayTimeout="5500"
                    :paginationEnabled="false"
                    :perPage='1'
                    :navigationEnabled="true"
                    :navigationNextLabel="''"
                    :navigationPrevLabel="''"
                    v-if='posts'>
                      <slide v-for='(item, key) in posts' :key='key'>
                          <div class="articles__item">
                            <router-link :to="item.link">
                                <div class="articles__img">
                                    <img :data-src="item.thumbnail" :alt="item.title" class="lozad">
                                </div>
                            </router-link>
                            <router-link :to="item.link" class="articles__title"><h3 v-html="item.title"></h3></router-link>
                            <span class="articles__info">{{item.date}} by {{item.author}}.</span>
                            <post-tags :tags="item.tags"></post-tags>
                        </div>
                      </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </section> -->
</template>

<script>
import PostTags from "@/components/partials/PostTags.vue";
export default {
      filters: {
        removeSpacesAndAddDot(text){
            return `${text.trim()}.`
        }
    },
	components: {
		PostTags
	},
    computed: {
        posts() {
			return this.$store.getters.getPosts.slice(0, 3);
		},
		postsPage() {
			return this.$store.getters.getOptionsPostsPage;
		},
    }
}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';

.all_articles {
	text-transform: uppercase;
	will-change: color;
	transition: color 200ms;
	&:hover {
		color: $brown;
	}
}

.articles {
    overflow: hidden;
    position: relative;
    padding: 32px 0 90px 0;
    @include tabletSize {
        padding: 32px 0;
    }
	&__title {
		text-decoration: none;
			h3{
				will-change: color;
				transition: color 200ms;
				color:black;
				&:hover {
					color: $brown;
				}
			}
	}
	&__info {
		text-transform: uppercase;
	}
    &__case {
        max-width: 1400px;
        margin: 0 auto;
        @include tabletSize {
            display: none;
        }
    }
    &__box {
        display: flex;
        padding: 0 5%;
        @include breakpoint-max('tablet') {
            padding: 0 10%;
        }
    }

    &__heading, &__heading-mobile {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 6%;
        h2 {
            font-size: 23px;
            color: $brown;
            font-weight: 300;
        }
        a {
            text-decoration: none;
            color: #000;
        }
        @include breakpoint-max('tablet') {
            padding: 0 11%;
        }
        @include tabletSize  {
            padding: 0 10px;
            margin-bottom: 24px;
            h2 {
                font-size: $small_txt;
            }
            a {
                line-height: 28px;
                font-size: $mobile_txt;
            }
        }
    }

    &__item {
        padding: 14px;
        width: 33.33%;
        height: 100%;
        width: 100%;
         & a:first-child:hover + .articles__title h3{
            color: $brown;
        }
        span {
            font-size: 14px;
            color: $dark_gray;
        }
        h3 {
            font-size: 34px;
            font-family: Financier;
            font-weight: 400;
            padding: 10px 0;
            @include tabletSize {
                font-size: 2.6rem;
            }
        }
        @include tabletSize {
            padding: 0 20px;
        }
    }
    &__img {
        text-align: center;

        img {
            max-height: 208px;
            min-height: 208px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

    }

    &__mobile {
        display: none;
        @include tabletSize {
            display: block;
            text-align: center;
        }
    }
}
.tags {
	height: auto;
    font-size: $mobile_txt;
	&__item {
		color: gray;
		text-decoration: none;
		text-transform: uppercase;
		will-change: color;
		transition: color 300ms;
		&:hover {
			color: $brown;
		}
	}
}
.gray-square {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 5%;
    width: 100%;
    background-color: $gray;
    z-index: -1;
    @include breakpoint-max('m-large'){
        left: 4%;
    }
    @include tabletSize {
        background-color: $gray;
        left: 0;
    }
}
.gray-left {
    left: auto;
    right: 3%;
    @include tabletSize {
        right: 0;
    }
}

.articles__heading-mobile {
    display: none;
    @include tabletSize {
        display: flex;

    }
}



</style>
