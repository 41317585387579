<template>
    <div class='wrapper'>
        <section class="search" :class="data.custom_class">
        <p v-if="data.title" v-html="data.title" class="search__additional-title"></p>
            <div class="container">
                <div class="search__select">
                    <div class="search__date-first">
                    <button @click="datefirst = true">
                        <div class="search__arrival">
                            <span class="search__arrival-span search__arrival-span--hide-on-mobile">arr.</span>
                            <span class="search__arrival-span">ARRIVAL</span>
                        </div>
                        <div class='search__inner-wrapper'>
                        <div class="search__number">
                            {{ String(dateArrive.getDate()).padStart(2, '0') }}
                        </div>
                        <div class="search__calendar">
                            <div class="search__month">
                                {{ monthNames[dateArrive.getMonth()] }}
                            </div>
                            <div class="search__year">
                                {{ dateArrive.getFullYear() }}
                            </div>
                        </div>
                        <img src="@/assets/img/arrow.svg" alt="arrow">
                    </div>
                    </button>
                    <transition name="show-modal">
                    <div class="date-first-modal" v-show="datefirst">
                        <datepicker :disabled-dates="disabledDatesArrive" :inline="true" v-model="dateArrive" :class='{"show" : datefirst}'></datepicker>
                        <div class="modal-bottom-custom" :class='{"show" : datefirst}'>
                            <button @click="arriveCommit" class="btn">OK</button>
                        </div>
                    </div>
                    </transition>
                    </div>
                    <div class="search__date-second">
                    <button @click="datesecond = true">
                        <div class="search__arrival">
                           <span class="search__arrival-span search__arrival-span--hide-on-mobile">dep.</span>
                           <span class="search__arrival-span">DEPARTURE</span>
                        </div>
                        <div class='search__inner-wrapper'>

                        <div class="search__number">
                            {{ String(dateDeparture.getDate()).padStart(2, '0') }}
                        </div>
                        <div class="search__calendar">
                            <div class="search__month">
                                {{ monthNames[dateDeparture.getMonth()] }}
                            </div>
                            <div class="search__year">
                                {{ dateDeparture.getFullYear() }}
                            </div>
                        </div>
                        <img src="@/assets/img/arrow.svg" alt="arrow">
                        </div>
                    </button>
                    <transition name="show-modal">

                    <div class="date-second-modal" v-if="datesecond">
                        <datepicker :disabled-dates="disabledDates" :inline="true" v-model="dateDeparture"></datepicker>
                        <div class="modal-bottom-custom">
                            <button @click="departureCommit" class="btn">OK</button>
                        </div>
                    </div>
                    </transition>
                    </div>
                    <div class="search__guests">
                    <button @click="guests = true">
                        <div class="search__arrival">
                            guests
                        </div>
                        <div class='search__inner-wrapper'>

                        <div class="search__number search__number--margin">
                            {{ adults + kids | formatNumber }}
                        </div>
                        <img src="@/assets/img/arrow.svg" alt="arrow">
                        </div>
                    </button>
                    <transition name="show-modal">
                    <div class="guests-modal" v-if="guests">
                        <div class="modal-topbar">
                            <p>Guests</p>
                            <button @click="guests = false"><img src="@/assets/img/small_close.svg" alt="Close"></button>
                        </div>
                        <div class="modal-calc">
                            <div class="modal-section">
                                <p>Adults</p>
                                <div class="modal-buttons">
                                    <button @click="adultsMinus" :class='{"grayColor" : adults == 1}'>-</button>
                                    <span class="search__number" :class='{"grayColor" : adults == 0}'>{{ adults | formatNumber }}</span>
                                    <button @click="adultsPlus">+</button>
                                </div>
                            </div>
                            <div class="modal-section">
                                <p>Kids (2-12)</p>
                                <div class="modal-buttons">
                                    <button @click="kidsMinus" :class='{"grayColor" : kids == 0}'>-</button>
                                    <span class="search__number" :class='{"grayColor" : kids == 0}'>{{ kids | formatNumber }}</span>
                                    <button @click="kidsPlus">+</button>
                                </div>
                            </div>
                            <div class="modal-section">
                                <p>Infants (0-2)</p>
                                <div class="modal-buttons">
                                    <button @click="infantsMinus" :class='{"grayColor" : infants == 0}'>-</button>
                                    <span class="search__number" :class='{"grayColor" : infants == 0}'>{{ infants | formatNumber}}</span>
                                    <button @click="infantsPlus">+</button>
                                </div>
                            </div>
                            <p class='modal-info-text'>Infants don't count toward the number of guests</p>

                        </div>
                        <div class="modal-bottom">
                            <button @click="guestsCommit">OK</button>
                        </div>
                    </div>
                    </transition>
                    </div>
                    <div class="search__bedrooms">
                    <button @click="bedroomsModal = true">
                        <div class="search__arrival">
                            bedrooms
                        </div>
                        <div class='search__inner-wrapper'>

                        <div class="search__number search__number--margin">
                            {{ bedrooms | formatNumber}}
                        </div>
                        <img src="@/assets/img/arrow.svg" alt="arrow">
                        </div>
                    </button>
                    <transition name="show-modal">
                    <div class="bedrooms-modal" v-if="bedroomsModal">
                        <div class="modal-topbar">
                            <p>Bedrooms</p>
                            <button @click="bedroomsModal = false"><img src="@/assets/img/small_close.svg" alt="Close"></button>
                        </div>
                        <div class="modal-calc">
                            <div class="modal-section">
                                <p>Bedrooms</p>
                                <div class="modal-buttons">
                                    <button @click="bedroomsMinus" :class='{"grayColor" : bedrooms == 1}'>-</button>
                                    <span class="search__number" :class='{"grayColor" : bedrooms == 0}'>{{ bedrooms| formatNumber }}</span>
                                    <button @click="bedroomsPlus">+</button>
                                </div>
                            </div>
                            <div class="modal-section modal-section--border-bottom">
                                <p>Kinderbed</p>
                                <div class="modal-buttons">
                                    <button @click="kinderbedMinus"  :class='{"grayColor" : kinderbed == 0}'>-</button>
                                    <span class="search__number" :class='{"grayColor" : kinderbed == 0}'>{{ kinderbed | formatNumber}}</span>
                                    <button @click="kinderbedPlus">+</button>
                                </div>
                            </div>
                        </div>
                        <div class="modal-bottom">
                            <button @click="bedroomsCommit">OK</button>
                        </div>
                    </div>
                    </transition>
                    </div>
                    <div class="search__enquire">
                        <button @click="navigateTo('/booking-enquiry')">Booking enquiry</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="background"></div>
        <img v-if="data.background_image" :src="data.background_image.url" alt="" class="search__background-image">
    </div>
</template>

<script>
import Scrollbar from 'smooth-scrollbar';
import Datepicker from 'vuejs-datepicker';
import axios from "axios";

export default{
    name: 'search',
    filters: {
        formatNumber(number) {
            if(number <= 9){
                return `0${number}`
            }else {
                return number
            }
        },
    },
    data(){
        return{
            active: false,
            datefirst: false,
            datesecond: false,
            bedroomsModal: false,
            dateArrive: new Date(),
            dateDeparture: new Date(new Date().setDate(new Date().getDate() + 1)),
            monthNames: [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
            adults: 1,
			disabledDatesArrive: {
				to: new Date(new Date().setDate(new Date().getDate() - 1))
			},
            kids: 0,
            infants: 0,
            bedrooms: 1,
            kinderbed: 0,
			name: "",
			comments: "",
			email_address: "",
			addons_list: ["VIP Transportation throughout your stay", "Beauty & Wellness","Detox & Diet", "Medical Services & Checkups", "Leisure Activities"],
			addons_picked: [],
			guests: false,
			submitButton: "Send",
			error: false,
			msg: ""

        }
    },
	watch: {
		datefirst: function(newValue){
			if(newValue){
				this.datesecond = false;
				this.bedroomsModal = false;
				this.guests = false;
			}
		},
		datesecond: function(newValue){
			if(newValue){
				this.datefirst = false;
				this.bedroomsModal = false;
				this.guests = false;
			}
		},
		bedroomsModal: function(newValue){
			if(newValue){
				this.datefirst = false;
				this.datesecond = false;
				this.guests = false;
			}
		},
		guests: function(newValue){
			if(newValue){
				this.datefirst = false;
				this.datesecond = false;
				this.bedroomsModal = false;
			}
		},
		dateArrive: function(newValue, oldValue){
			if((this.dateDeparture < newValue) || (this.dateDeparture.toDateString() == newValue.toDateString())){
				this.dateDeparture = new Date(new Date().setDate(newValue.getDate() + 1))
			}
			this.arriveCommit()
		},
		dateDeparture: function(){
				this.departureCommit()
		}
	},
    components: {
        'datepicker': Datepicker
    },
	computed: {
		disabledDates(){
			let oneDayMore = new Date(new Date().setDate(this.dateArrive.getDate()))
			return {
				to: oneDayMore
			}
		},
		villas(){
			return this.$store.getters.getAllVillas;
		},
		pickedVilla(){
			return this.$store.getters.getPickedVilla;
		}
	},
    methods: {
        navigateTo(url){
            const publicPath = '/' + this.$store.getters.getPublicPath
            const locale = this.$store.getters.getLocale
            if(url != '#'){
                this.$router.push(url)
                this.active = false
            }
        },
		arriveCommit(){
			this.datefirst = false;
			this.$store.commit("SET_ARRIVE_DATE",this.dateArrive);
		},
		departureCommit(){
			this.datesecond = false;
			this.$store.commit("SET_DEPARTURE_DATE",this.dateDeparture);
		},
		guestsCommit(){
			this.guests = false;
			this.$store.commit("SET_ADULTS",this.adults);
			this.$store.commit("SET_KIDS",this.kids);
			this.$store.commit("SET_INFANTS",this.infants);
		},
		bedroomsCommit(){
			this.bedroomsModal = false;
			this.$store.commit("SET_BEDROOMS",this.bedrooms);
			this.$store.commit("SET_KINDERBEDS",this.kinderbed);
		},
        bedroomsMinus(){
            if (this.bedrooms - 1 == 0) {
                return
            }else{
                this.bedrooms--
            }
        },
        bedroomsPlus(){
            if (this.bedrooms + 1 == 21) {
                return
            }else{
                this.bedrooms++
            }
        },
        kinderbedMinus(){
            if (this.kinderbed - 1 == -1) {
                return
            }else{
                this.kinderbed--
            }
        },
        kinderbedPlus(){
            if (this.kinderbed + 1 == 21) {
                return
            }else{
                this.kinderbed++
            }
        },
        adultsMinus(){
            if (this.adults - 1 == 0) {
                return
            }else{
                this.adults--
            }
        },
        adultsPlus(){
            if (this.adults + 1 == 21) {
                return
            }else{
                this.adults++
            }
        },
        kidsMinus(){
            if (this.kids - 1 == -1) {
                return
            }else{
                this.kids--
            }
        },
        kidsPlus(){
            if (this.kids + 1 == 21) {
                return
            }else{
                this.kids++
            }
        },
        infantsMinus(){
            if (this.infants - 1 == -1) {
                return
            }else{
                this.infants--
            }
        },
        infantsPlus(){
            if (this.infants + 1 == 21) {
                return
            }else{
                this.infants++
            }
        },
        menuEnter(el, done) {

            let logotype = document.querySelector(".logotype");
            let tagline = document.querySelector(".tagline");

            const logoText = document.querySelectorAll(".c");

            logoText.forEach(item => {
                item.style.fill = "#2E2E33";
            })

			const tl = new TimelineMax({
				onComplete: done
			})

            tl.set(".search__close", {
                opacity: 0
            })

            tl.set(logotype, {
				opacity: 0,
                y: -20
			})

            tl.set(tagline, {
				opacity: 0,
                y: -20
			})

            tl.set(el, {
				scaleX: 0,
				transformOrigin: "0% top"
			})

			tl.to(el, .8, {
				scaleX: 1,
				transformOrigin: "100% top",
				ease: Power2.easeOut
			})


            tl.to(logotype, 1, {
                opacity: 1,
                y: 0,
                ease: Power2.easeOut
            })

            tl.to(tagline, 1, {
                opacity: 1,
                y: 0,
                ease: Power2.easeOut
            }, "-=.7")

            tl.to(".search__close", 1, {
                opacity: 1,
                ease: Power2.easeOut
            }, "-=.7")
		},
		menuLeave(el, done) {

			const tl2 = new TimelineMax({
				onComplete: done
			})

            tl2.to(".tagline", .8, {
				opacity: 0
			}, 0.15)

			tl2.to(".logotype", .8, {
				opacity: 0
			}, 0.15)

			tl2.to(".search__close", .8, {
				opacity: 0
			}, 0.15)

            tl2.to(el, .5, {
				scaleX: 0,
				ease: Power2.easeOut,
				onComplete: done
			})

        },

		formatDate(date){
			let day = date.getDate();
			let month = date.getMonth() + 1;
			let year = date.getFullYear();
			if(date){
				return `${day}.${month}.${year}`
			} else return "";
		}
    },
    mounted(){
		this.$bamUtils.syncEnquiry(this)

		if(document.querySelector('.search__scroller')){
			var scroll = Scrollbar.init(
				document.querySelector('.search__scroller')
			)
		}
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';

.search{
    position: relative;
    &__select{
        width: 100%;
        max-width: 1440px;
        min-height: 120px;
        width: 100%;
        background-color: #FFF;
        bottom: 0;
        position: absolute;
        z-index: 800;
        transform: translateY(80px);
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 60px;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.13);
        @include breakpoint-max('tablet'){
            max-height: 300px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 1px 1px;
            grid-template-areas: "search__date-first search__date-second . ."
                                 "search__guests search__bedrooms . ."
                                 "search__enquire search__enquire . .";
            width: calc(100% - 60px);
            left: 50%;
            transform: translateX(-50%) translateY(80px);
            column-gap: 0;
        }
        &--custom{
            grid-template-columns: repeat(4, 1fr);
            background-color: transparent;
            box-shadow: none;
            border-top: 1px solid rgba(102, 98, 95, 0.3);
            border-bottom: 1px solid rgba(102, 98, 95, 0.3);
            position: relative;
            transform: none;
            @include breakpoint-max('tablet'){
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                gap: 1px 1px;
                grid-template-areas: "search__date-first search__date-second . ."
                                     "search__guests search__bedrooms . ."
                                     "search__enquire search__enquire . .";
                width: calc(100% - 0px);
                left: 0%;
                // transform: translateX(-50%) translateY(80px);
                column-gap: 0;
            }
        }
    }
    &__custom-li{
        color: $text_color;
    }
    &__enquire{
        @include breakpoint-max('tablet'){
            grid-area: search__enquire;
            padding-bottom: 15px;
        }
        button{
            font-size: 1.4rem;
            width: 100%;
            height: 100%;
            border: 0;
            outline: none;
            background-color: $gold_sand;
            color: #FFF;
            text-transform: uppercase;
            transition: background-color .8s;
            @include breakpoint-max('tablet'){
                width: 80%;
                height: 85%;
                font-size: 1.2rem;
                display: block;
                margin: 0 auto;
            }
            &:hover{
                background-color: darken($gold_sand, 10%);
            }
        }
    }
    &__inner-wrapper{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        @include breakpoint-max('tablet'){
           transform: translateX(5px);
        }
    }
    &__date-first, &__date-second{
        padding: 0 30px;
        position: relative;
        @include breakpoint-max('tablet'){
            padding:0 30px;
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }
        @include breakpoint-max('mobile'){
            padding: 0 30px 10px;
            position: static;
        }
        & > button{
            outline: none;
            background-color: transparent;
            border: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
         @include breakpoint-max('tablet'){
                max-width: 100px;
                display: grid;
                grid-template-rows: repeat(2, 1fr);
                grid-template-columns: repeat(3, auto);
                .search__arrival{
                    grid-column: 1 /  4;
                    margin-bottom: 5px;
                }
                .search__number{
                     grid-row: 2 / 2;
                }
                .search__calendar{
                     grid-row: 2 / 2;
                     margin: 0 10px;
                }
                img{
                     grid-row: 2 / 2;

                }
            }
        }
    }
    &__date-first{
        @include breakpoint-max('tablet'){
            grid-area: search__date-first;
        }

    }
    &__date-second{
        @include breakpoint-max('tablet'){
            grid-area: search__date-second;
        }
    }
    &__arrow{
        outline: none;
        border: 0;
    }
    &__datepicker{
        z-index: 800;
    }
    &__arrival{
        text-transform: uppercase;
        font-size: 1.4rem;
        @include breakpoint-max('mobile'){
            font-size: 1rem;
        }
    }
    &__arrival-span{
        display: none;
        @include breakpoint-max('tablet'){
            display: initial;
        }
        &--hide-on-mobile{
            display: initial;
            margin:0 10px 0;
        @include breakpoint-max('tablet'){
            display: none;
            margin-right: 0;

        }
        }
    }
    &__guests, &__bedrooms{
        padding: 0 30px;
        position: relative;
        @include breakpoint-max('tablet'){
            padding: 0 30px 30px 30px;
        }
        @include breakpoint-max('mobile'){
            padding: 0 30px 15px 30px;
            position: static;
        }
        button{
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 0;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            .hero__arrow{
                margin-left: 8px;
            }
            .hero__arrival{
                margin-right: 8px;
            }
        }
    }
    &__guests{
        @include breakpoint-max('tablet'){
            grid-area: search__guests;
            justify-self: center;
        }
      & > button{
          @include breakpoint-max('tablet'){
                max-width: 100px;
                display: grid;
                grid-template-rows: repeat(2, 1fr);
                grid-template-columns: repeat(2, 1fr);
                .search__arrival{
                    grid-column: 1 / span 4;
                }
                .search__number--margin{
                    text-align: right;
                    margin:0  10px;
                }
            }
        }
    }
    &__bedrooms{
        @include breakpoint-max('tablet'){
            grid-area: search__bedrooms;
            justify-self: center;
        }
         & > button{
          @include breakpoint-max('tablet'){
                max-width: 100px;
                display: grid;
                grid-template-rows: repeat(2, 1fr);
                grid-template-columns: repeat(2, 1fr);
                .search__number--margin{
                    text-align: right;
                    margin:0  10px;
                }
                .search__arrival{
                    grid-column: 1 / span 4;
                }
            }
        }
    }
    &__number{
        font-size: 4.4rem;
        font-family: 'Financier';
        @include breakpoint-max('mobile'){
            font-size: 2.8rem;
        }
        &--margin{
            margin: 0 10px;
        }
    }
    &__month{
        text-transform: uppercase;
        color: $brown;
        font-size: 1.4rem;
        @include breakpoint-max('mobile'){
            font-size: 1rem;
        }
    }
    &__year{
        color: $text_color;
        line-height: 15px;
        font-size: 1.4rem;
        @include breakpoint-max('mobile'){
            font-size: 1rem;
        }
    }
    &__background-image{
        display: none;
    }
    &__additional-title{
        text-transform: uppercase;
        color: $brown;
        font-size: 2.3rem;
        line-height: 3.4rem;
        font-family: 'Poppins';
        text-align: center;
        width: 100%;
        padding-bottom: 20px;

    }
    // Overlay

    &__overlay{
        width: 100%;
        position: relative;
        z-index: 999;
        top: 0;
        left: 0;
        background-color: $beige;
    }
    &__close{
        width: 40px;
        height: 40px;
        outline: none;
        border: 0;
        position: fixed;
        right: 40px;
        top: 40px;
        z-index: 995;
        background-color: transparent;
        @include tabletSize{
            top: 65px;
            right: 15px;
        }
    }
    &__scroller{
        -webkit-overflow-scrolling: touch;
        height: 100%;
        overflow: auto;
        width: 100%;
    }
    &__place{
        padding-bottom: 80px;
        border-bottom: 1px solid rgba(102, 98, 95, 0.3);
    }
    &__places{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 27px;
        @include breakpoint-max('tablet'){
            // grid-template-columns: repeat(2, 1fr);
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: row;
        }

    }
    &__residence{
        width: 100%;
        @include breakpoint-max('tablet'){
          margin: 0 2.5%;
          width: 45%;
        }

		&.picked .search__image::after {
			opacity:1;
		}
		&.picked .search__image::before {
			opacity:0.7;
		}
		.search__image {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left:0;
				bottom: 0;
				right: 0;
				background: $brown;
				opacity: 0;
				will-change: opacity;
				transition: opacity 0.1s;
			}
			&::after {
				content: 'PICKED';
				position: absolute;
				top: 0;
				left:0;
				bottom: 0;
				right: 0;
				opacity: 0;
				z-index: 100;
				display: flex;
				align-items: center;
				justify-content: center;
				color: white;
				font-size: 2rem;
			}
		}
        p{
            color: $brown;
            text-align: center;
            text-transform: uppercase;
            font-size: 2.3rem;
            margin: 15px 0 10px 0;
        }
    }
    &__image{
        width: 100%;
        height: 220px;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__header{
        font-size: 2.3rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: 300;
        color: $brown;
        margin-bottom: 20px;
    }
    &__one{
        font-size: 1.4rem;
        color: $black_brown;
        &--custom{
            margin-top: 80px;
            @include breakpoint-max('xs-tablet'){
                margin-left: 20px;
                margin-right: 20px;
            }
        }
        &--center{
          @include breakpoint-max('xs-tablet'){
            text-align: center;
          }
        }
        &--center-mobile{
          @include breakpoint-max('xs-tablet'){
            text-align: center;
          }
        }
    }
    &__choose{
        font-size: 4.4rem;
        color: $black_brown;
        font-family: 'Financier';
        font-weight: 300;
        margin-bottom: 12px;
        @include breakpoint-max('xs-tablet'){
            font-size: 2.6rem;
        }
        &--center{
            text-align: center;

        }
        &--custom{
            @include breakpoint-max('xs-tablet'){
                margin: 0 20px;
                margin-bottom: 20px;
            }
        }
        &--center-mobile{
          @include breakpoint-max('xs-tablet'){
            text-align: center;
          }
        }
    }

    // Amenities

    &__amenities{
        border-bottom: 1px solid rgba(102, 98, 95, 0.3);
        display: flex;
        padding: 80px 0;
    }
    &__image-amenities{
        flex: 1;
        margin-left: 50px;
        margin-right: 60px;
        @include breakpoint-max('s-tablet'){
            display: none;
        }
    }
    &__services{
        flex: 4;
        @include breakpoint-max('s-tablet'){
            margin: 0 50px;
        }
        @include breakpoint-max('xs-tablet'){
            margin: 0 20px;
        }
    }
    &__lists{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-right: 50px;
        @include breakpoint-max('tablet'){
            flex-direction: column;
            margin-right: 0px;
            align-items: center;
        }
    }
    &__list{
        margin-right: 20px;
        margin-top: 40px;
        span{
            font-size: 1.4rem;
            color: $brown;
        }
        ul{
            margin-top: 10px;
            list-style: none;
            li{
                color: $text_color;
                line-height: 37px;
            }
        }
        @include breakpoint-max('tablet'){
            margin-right: 0px;
            text-align: center;
            padding: 5%;
            border: 1px solid #E2DAD2;
        }
    }

    // Comments

    &__comments{
        display: flex;
        padding: 80px 0;
        border-bottom: 1px solid rgba(102, 98, 95, 0.3);
        @include breakpoint-max('xs-tablet'){
            flex-direction: column;
        }
        &-left{
            flex: 2;
            margin-left: 50px;
            @include breakpoint-max('xs-tablet'){
                margin: 0 20px;
                text-align: center;
            }
        }
        &-right{
            flex: 4;
            margin-right: 50px;
            @include breakpoint-max('xs-tablet'){
                margin: 0;
                margin-top: 20px;
                flex: 100%;
            }
            textarea{
                width: 100%;
                min-height: 300px;
                background-color: transparent;
                resize: none;
                outline: none;
                padding: 20px;
                font-size: 2rem;
                border: 1px solid $text_color;
                opacity: .5;
                @include breakpoint-max('xs-tablet'){
                    width: calc(100% - 40px);
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    }

    // Contact

    &__contact{
        padding: 47px 0 80px 0;
    }
    &__check{
        max-width: 600px;
        width: 100%;
        margin: 20px auto;
        display: flex;
        align-items: center;
        color: $text_color;
        @include breakpoint-max('mobile'){
            width: calc(100% - 40px);
        }
        input{
            margin-right: 10px;
        }
    }
    &__inputs{
        display: flex;
        justify-content: center;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
        @include breakpoint-max('mobile'){
            width: calc(100% - 40px);
            flex-direction: column;
        }
        input{
            font-size: 1.7rem;
            background: transparent;
            border: 0;
            color: $text_color;
            border: 1px solid $dark_beige;
            padding: 15px 17px;
            outline: none;
            max-width: 350px;
            width: 100%;
            flex: 1;
            @include breakpoint-max('mobile'){
                flex: 100%;
                max-width: none;
            }
            &:first-child{
                margin-right: 8px;
                @include breakpoint-max('mobile'){
                    margin-right: auto;
                    margin-bottom: 8px;
                }
            }
        }
    }
    &__submit{
        padding: 15px 17px;
        outline: none;
        max-width: 350px;
        width: 100%;
        margin: 8px;
        font-size: 1.7rem;
        background: transparent;
        border: 0;
        background-color: $gold_sand;
        color: #FFF;
        text-transform: uppercase;
        display: block;
        margin: 0 auto;
        @include breakpoint-max('mobile'){
            max-width: none;
            width: calc(100% - 40px);
        }
    }
}

.guests-modal, .date-first-modal, .date-second-modal, .bedrooms-modal{
    position: absolute;
    top: 0;
    display: block;
    left: 0;
    padding: 30px;
    background-color: #FFF;
    z-index: 800;
    min-width: 350px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.13);
    @include breakpoint-max('tablet'){
        left: 50%;
        transform: translateX(-50%);
    }
}

.modal-topbar{
    text-align: center;
    border-bottom: 1px solid #E0E0E0;
    p{
        color: $black_brown;
        font-size: 1.4rem;
        margin-bottom: 14px;
        text-transform: uppercase;
    }
    button{
         position: absolute;
        width: 35px;
        height: 35px;
        right: 15px;
        top: 15px;
        padding: 10px;
    }
}

.modal-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    button{
        width: 30px;
        font-size: 2rem;
    }
}

.modal-section{
    text-align: center;
    margin: 30px 0;
    &--border-bottom{
    margin: 30px 0 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E0E0E0;
    }
    p{
        text-transform: uppercase;
        color: #828282;
        font-size: 1.4rem;
    }
}

.modal-bottom{
    button{
        color: $brown;
        padding: 5px 0;
        font-size: 1.4rem;
    }
}

.modal-bottom-custom{
       display: flex;
        justify-content: center;
    button{

        margin-top: 10px;
        color: $brown;
        padding: 5px 10px;
        font-size: 1.4rem;
        &.btn{
            background-color: transparent;
            border: none;
            max-width: none !important;
        }
    }
}

.custom-container{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    margin-top: 270px;
    @include breakpoint-max('tablet'){
      margin-top: 150px;

    }
}

// Datepicker customization

/deep/.vdp-datepicker__calendar .cell.selected, /deep/.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover{
    border-radius: 50%;
    border: 1px solid $gold_sand;
    background: none;
}


.respond {
	color: green;
	padding-top: 10px;
	text-align: center;
	&.error {
		color: red;
	}
}
.modal-info-text{
    color: #828282;
    font-size: 1.5rem;
    line-height: 2.3rem;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 20px;
}
.grayColor{
    color: #BDBDBD;
}
.background{
    display: none;
}
.wrapper{
    position: relative;
}
.search.as-element{
    width: 78%;
    max-width: 1134px ;
    margin: 0 auto 380px;
      @include breakpoint-max('tablet'){
          padding: 0 8%;
          width: 100%;
        margin: 0 auto 350px;

      }
    .search__select{
           grid-template-columns: repeat(4, 1fr);
            background-color: transparent;
            box-shadow: none;
            position: relative;
            border-top: 1px solid rgba(102, 98, 95, 0.3);
            border-bottom: 1px solid rgba(102, 98, 95, 0.3);
            position: relative;
            transform: none;
               @include breakpoint-max('big'){
           & > div {
                padding: 0 15px
            }
            }
            @include breakpoint-max('tablet'){
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                gap: 1px 1px;
                grid-template-areas: "search__date-first search__date-second . ."
                                     "search__guests search__bedrooms . ."
                                     "search__enquire search__enquire . .";
                width: calc(100% - 0px);
                left: 0%;
                // transform: translateX(-50%) translateY(80px);
                column-gap: 0;
                row-gap: 10px;
        }
    }
    .search__enquire{
        position: absolute;
        bottom: -112px;
        left:50%;
        min-width:278px;
        height: 62px;
        font-size: 1.4rem;

        transform: translateX(-50%);
    }
    & ~ .search__background-image{
        display: block;
        position: absolute;
        left: 0;
        bottom: -200%;
        z-index: -3;
        width: 100%;
        height: 100vh;
        max-width: 277px;
        max-height: 500px;
        object-fit: cover;
        @include breakpoint-max('tablet'){
            bottom: -110%;
      }
          @include breakpoint-max('mobile'){
            bottom: -140%;
      }
    }
    & + .background{
    display: block;
    position: absolute;
    left: 10%;
    right: 0;
    bottom: 0;
    height: 300%;
    top: -30%;
    background-color: #F4F2EE;
    z-index: -1;
     @include breakpoint-max('tablet'){
             height: 200%;
            left: 0;
      }
     @include breakpoint-max('mobile'){
            height: 240%;

      }
}

}
.search.as-element.smaller-background{
      & ~ .search__background-image{
        display: block;
        position: absolute;
        left: 0;
        bottom: -155%;
        z-index: -3;
        width: 100%;
        height: 100vh;
        max-width: 277px;
        max-height: 400px;
        object-fit: cover;
        @include breakpoint-max('tablet'){
            bottom: -110%;
      }
          @include breakpoint-max('mobile'){
            bottom: -110%;
      }
    }
      & + .background{
    display: block;
    position: absolute;
    left: 5%;
    right: 0;
    bottom: 0;
    height: 280%;
    top: -60%;
    background-color: #F4F2EE;
    z-index: -1;
     @include breakpoint-max('tablet'){
             height: 200%;
            top: -25%;

            left: 0;
      }
     @include breakpoint-max('xs-tablet'){
            top: -20%;
            height: 200%;

      }
}
}
.search.as-element.smaller-background.reverse-background{
     & ~ .search__background-image{
         left: auto;
         right: 0;

     }
    & + .background{
        left: 0;
        right: 5%;
    @include breakpoint-max('tablet'){
            right: 0;
            left: 0;
      }
    }
}

// .show-modal-enter{}
// .show-modal-enter-active, .show-modal-leave-active {
//     transition: opacity .3s ease;
// }
// .show-modal-enter, .show-modal-leave-to
// /* .component-fade-leave-active below version 2.1.8 */ {
//     opacity: 0;
// }

.show-modal-enter-active,
.show-modal-leave-active {
  transition: all 0.4s;
  max-height: 500px;
  overflow: hidden;
}

.show-modal-enter,
.show-modal-leave-to,
.show-modal-child-active,
.show-modal-leave-to{
  opacity: 0;
  max-height: 0px;
}

</style>
