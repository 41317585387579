function videoObserver(el, options = false,passFirstIntersection = true){
	let intersectionOptions = options;
	if(!intersectionOptions){
		intersectionOptions = {
			root: document.querySelector('#app'),
			rootMargin: '0px',
			threshold: 0.4
		}
	}
	let firstIntersection = passFirstIntersection;
	let isPaused = true;

	let observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if(!firstIntersection){
			if (entry.intersectionRatio != 1 && !el.paused) {
				el.pause();
				isPaused = true;
			} else if (isPaused) {
				el.play();
				isPaused = false
			}} else {
				firstIntersection = false;
			}
		});
	}, intersectionOptions);

	observer.observe(el);
}


export default videoObserver;
