<template>
	<section class="teampost">
		<div class="teampost__background">
            <img src="@/assets/img/marble.png" alt="">
        </div>
        <div class="teampost__container">
            <div class="teampost__heading">
                <router-link to="/team" class="team_page_link"><h2>team</h2></router-link>
                <h3>{{post.title}}</h3>
				<span v-if="post.position">{{post.position}}</span>
				<div class="teampost__heading-case">
					<back-button></back-button>
				</div>
            </div>
            <div class="teampost__box">
                <!-- <div class="team__gray-bgc"></div> -->
                <div class="teampost__person">
                    <div class="teampost__img">
                        <img :src="post.image" :alt="generateAlt(post)">
                    </div>
                    <div v-if='post.content'  class="teampost__content">
                    	<p v-html="post.content"></p>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>
<script>
import BackButton from "@/components/partials/BackButton.vue";
	export default {
		components: {
			BackButton
		},
		computed: {
			post(){
				return this.$store.getters.getPageByPath(this.$route.path)
			}
		},
		methods: {
			generateAlt(member){
				if(member.position){
					return member.title + ' - ' + member.position
				} else return member.title
			}
		},
	}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';

.team_page_link {
	text-decoration: none;
	h2{
		text-decoration: none;
		will-change: color;
		transition: color 200ms;
		&:hover {
			color: lighten($brown,15%);
		}
	}
}
.teampost {
    padding: 160px 0 0 80px;
    position: relative;
    @include tabletSize {
        padding: 0;
    }
    &__background {
        position: absolute;
        width: 100%;
        height: 50%;
        top: -160px;
        left: 0;
        z-index: -1;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        @include tabletSize {
            display: none;
        }
    }
    &__container {
        background-color: $gray;
        @include mobile {
            background-color: $white;
        }
    }
    &__heading {
    	position: relative;
        padding: 92px 240px 62px 240px;
        text-align: center;
		display: flex;
		flex-direction: column;
        h2 {
            font-size: $small_heading;
            color: $brown;
            text-transform: uppercase;
            font-weight: 400;
        }
		h3 {
			color: $black_brown;
			font-size: $big_heading;
		}
		span {
			color: $text_color;
			font-size: $small_heading;
		}
		@include tabletSize {
			padding: 92px 100px 62px 100px;
			h2 {
				font-size: $small_txt;
			}
			h3 {
				font-size: $mobile_heading;
			}
			span {
				font-size: $small_txt;
			}
		}
		@include mobile {
			padding: 92px 40px 62px 40px;
		}
    }
	&__heading-case {
		display: flex;
		align-items: center;
		position: absolute;
		left: 12%;
		top: 96px;
		@include tabletSize {
			display: none;
		}
	}
	&__heading-btn {
		font-size: $mobile_txt;
		color: $black_brown;
		text-decoration: none;
		margin-left: 6px;
	}
    &__person {
        display: flex;
		max-width: 1200px;
		margin: 0 auto;
		align-items: center;
		@include tabletSize {
			flex-direction: column;
			justify-content: center;
		}
    }
	&__img {
		img {
			height: 300px;
			width: 300px;
			border-radius: 50%;
			object-fit: cover;
			// filter: grayscale(100%);
		}
		@include tabletSize {
			margin: 0 0 30px 0;
		}
	}
	&__content {
		display: flex;
		align-items: center;
		margin-left: 70px;

		p {
			color: $text_color;
			font-size: $small_txt;
		}
	}

    &__gray-bgc {
        display: none;
        @include tabletSize {
            display: block;
            position: absolute;
            top: -30px;
            left: 0;
            width: 100%;
            height: 15%;
            background-color: $gray;
        }
    }
    &__box {
        padding: 0 100px 187px 100px;
        height: 100%;
        width: 100%;
        display: flex;
        @include breakpoint-max('m-large') {
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint-max('big') {
            grid-template-columns: repeat(2, 1fr);
        }
		@include tabletSize {
			padding: 0 30px 140px 30px;
		}
        @include mobile {
            position: relative;
            grid-template-columns: 1fr;
        }
    }
}

</style>
