<template>
  <footer class="footer">
    <div class="footer__logo">
      <img src="@/assets/img/logo_footer.svg" alt="Atrium">
    </div>
    <div class="container-big">
      <div class="footer__grid">
        <div class="footer__navigate">
          <ul>
            <li>NAVIGATE</li>
            <li v-for="(menuItem, index) in mainMenu" :key="index" v-if="menuItem.title">
              <a
                  :href="'/'+menuLocale+menuItem.url"
                  @click.prevent="navigateTo('/'+menuItem.url)"
                  v-html="menuItem.title"
              ></a>
            </li>
			 <li>
              <a
                  href="/career"
              >Career</a>
            </li>
          </ul>
        </div>
        <div class="footer__help">
          <ul>
            <li>NEED HELP</li>
            <!--                        <li><a href="#">Customer Service</a></li>-->
            <!--                        <li><a href="#">Support</a></li>-->
            <!--                        <li><a href="#">FAQ</a></li>-->
            <li><a href="/terms-conditions">Terms & Conditions<br/>and Privacy</a></li>
          </ul>
        </div>
        <div class="footer__social">
          <ul>
            <li>SOCIAL MEDIA</li>
            <li><a :href="socialLinks.facebook" target="_blank">Facebook</a></li>
            <li><a :href="socialLinks.linkedin" target="_blank">LinkedIn</a></li>
            <li><a :href="socialLinks.youtube" target="_blank">YouTube</a></li>
            <li><a :href="socialLinks.instagram" target="_blank">Instagram</a></li>
          </ul>
          <div class="footer__big-logo">
            <svg width="280" height="27" viewBox="0 0 280 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10.4931 5.42983L6.53517 16.1991H14.5737L10.4931 5.42983ZM7.27153 26.2626H0V25.8024C1.31931 25.7103 2.30112 25.4649 2.85339 23.9615L10.1249 4.38666L9.11243 1.74805H14.4817L23.0419 24.4524C23.41 25.4649 24.2691 25.741 25.4043 25.8024V26.2626H14.6044V25.8024C16.568 25.741 17.4578 25.3422 17.4578 24.2683C17.4578 23.9308 17.3044 23.4706 17.151 22.9183L14.8499 16.8741H6.3204L4.14201 22.857C3.95792 23.3785 3.89656 23.7774 3.89656 24.1456C3.89656 25.2501 5.03178 25.7103 7.30221 25.7717V26.2626H7.27153Z"
                  fill="#D1BEA6"/>
              <path
                  d="M32.0316 8.25287H36.6338V9.48013H32.0316V20.8937C32.0316 23.1028 33.0134 24.0846 34.7623 24.0846C35.6214 24.0846 36.5725 23.8391 37.3702 23.2868L37.4622 23.4403C36.4191 25.0664 34.5475 26.6618 32.093 26.6618C29.2089 26.6618 27.2146 25.0357 27.2146 21.2925V9.51082H25.0669V9.17332C27.3987 8.09946 30.3134 5.79835 31.8168 3.55859H32.0316V8.25287Z"
                  fill="#D1BEA6"/>
              <path
                  d="M51.1155 7.54785C51.7599 7.54785 52.3428 7.7933 52.6189 8.00807V11.9967H51.9746C49.7962 11.9967 47.8019 12.1808 46.3599 13.6228V24.3C46.3599 25.5579 47.1883 25.6807 49.336 25.8647V26.2943H39.2725V25.8647C40.8986 25.7727 41.5122 25.6193 41.5122 24.3613V11.1069L39.3645 9.57284V9.41943L46.3292 7.54785H46.4826L46.3292 13.2546C47.4951 9.78761 48.8451 7.54785 51.1155 7.54785Z"
                  fill="#D1BEA6"/>
              <path
                  d="M56.6076 2.73066C56.6076 1.22726 57.7121 0 59.4303 0C61.0871 0 62.253 1.25794 62.253 2.73066C62.253 4.17269 61.1178 5.46132 59.4303 5.46132C57.7121 5.492 56.6076 4.20337 56.6076 2.73066ZM61.8848 24.3611C61.8848 25.6191 62.4984 25.7418 64.0939 25.8646V26.2941H54.7974V25.8646C56.4235 25.7725 57.0371 25.6191 57.0371 24.3611V11.1067L54.8894 9.57265V9.41924L61.7314 7.51698H61.8848V24.3611Z"
                  fill="#D1BEA6"/>
              <path
                  d="M83.9753 23.2869C83.9753 24.5449 84.1594 24.9131 86.2765 25.0051V25.3426L79.189 26.6006H79.0356L79.1276 23.1949C77.8083 24.8517 75.6606 26.6006 73.0834 26.6006C70.1379 26.6006 68.0823 24.821 68.0823 20.9858V11.2291C68.0823 9.97113 67.8061 9.54158 65.9346 9.17341V8.89727L72.93 7.54729H73.0834L72.8993 11.6586V20.5256C72.8993 22.7347 73.7584 23.9313 75.8447 23.9313C76.9799 23.9313 78.2379 23.5938 79.097 22.8574V11.1984C79.097 9.94045 78.8515 9.51091 76.9799 9.14273V8.86659L83.9447 7.5166H84.0981L83.914 11.5666V23.2869H83.9753Z"
                  fill="#D1BEA6"/>
              <path
                  d="M119.535 25.864V26.2936H110.362V25.864C111.957 25.772 112.509 25.6186 112.509 24.3606V13.4993C112.509 11.3516 111.742 10.2778 109.871 10.2778C108.613 10.2778 107.416 10.7687 106.434 11.4437V24.3299C106.434 25.5879 106.987 25.7106 108.582 25.8333V26.2629H99.4698V25.8333C101.065 25.7413 101.617 25.5879 101.617 24.3299V13.4687C101.617 11.3209 100.85 10.2471 98.9789 10.2471C97.7209 10.2471 96.5243 10.738 95.5425 11.413V24.2993C95.5425 25.5572 96.0948 25.6799 97.6902 25.8026V26.2322H88.4551V25.8026C90.0812 25.7106 90.6948 25.5572 90.6948 24.2993V11.0448L88.5471 9.51074V9.35733L95.5118 7.45508H95.6652L95.5118 11.1062C96.6777 9.51074 98.9482 7.45508 101.648 7.45508C104.164 7.45508 105.852 8.77438 106.373 11.1369C107.539 9.51074 109.84 7.45508 112.54 7.45508C115.7 7.45508 117.296 9.51074 117.296 13.1005V24.2686C117.326 25.6186 117.94 25.772 119.535 25.864Z"
                  fill="#D1BEA6"/>
              <path
                  d="M131.716 2.48546L129.2 14.3285H131.102C135.613 14.3285 138.681 11.9354 138.681 7.45587C138.681 4.69453 137.3 2.45477 133.925 2.45477H131.716V2.48546ZM127.206 23.4716C126.746 25.5887 127.451 25.65 129.722 25.9262L129.66 26.2637H120.302L120.364 25.9262C123.002 25.6193 123.524 25.4353 123.923 23.4716L127.942 4.75589C128.402 2.63886 127.697 2.5775 125.426 2.30137L125.488 1.96387H134.784C140.307 1.96387 142.178 4.69453 142.178 7.45587C142.178 11.6899 138.159 13.7763 134.661 14.6047L137.883 20.8023C139.847 24.6375 141.289 25.8955 143.191 25.9568L143.13 26.2943H137.607C136.717 25.4046 135.674 23.6864 134.569 21.4466L131.409 15.0035H129.047L127.206 23.4716Z"
                  fill="#D1BEA6"/>
              <path
                  d="M146.934 17.6114C152.242 16.3841 154.942 14.2978 154.942 10.6774C154.942 9.51146 154.482 8.46828 153.132 8.46828C150.278 8.46828 147.609 13.1012 146.934 17.6114ZM149.205 26.6318C145.738 26.6318 143.774 24.1159 143.774 20.4034C143.774 14.6966 148.56 7.91602 153.746 7.91602C156.476 7.91602 157.703 9.45009 157.703 11.291C157.703 15.4023 152.058 17.0898 146.873 18.0103C146.811 18.5932 146.781 19.1455 146.781 19.7591C146.781 23.0113 148.131 24.8522 150.463 24.8522C152.518 24.8522 154.267 23.5943 155.832 21.3852L156.077 21.5386C154.482 24.5147 151.997 26.6318 149.205 26.6318Z"
                  fill="#D1BEA6"/>
              <path
                  d="M167.798 22.6125C167.798 19.2989 161.477 17.1819 161.477 13.2853C161.477 10.9535 164.054 7.91602 167.552 7.91602C169.945 7.91602 171.479 9.17395 171.479 10.8614C171.479 11.8432 170.559 12.5489 169.761 12.8864H169.669C169.393 10.6774 167.828 8.86714 165.865 8.86714C164.3 8.86714 163.41 10.033 163.41 11.3217C163.41 14.3285 169.792 16.875 169.792 20.5568C169.792 23.5329 166.386 26.6625 163.042 26.6625C160.311 26.6625 158.532 24.8522 158.532 23.0113C158.532 21.9989 159.299 20.9864 160.25 20.6182H160.311C160.679 23.2875 162.612 25.5886 164.791 25.5886C166.417 25.5579 167.798 24.3 167.798 22.6125Z"
                  fill="#D1BEA6"/>
              <path
                  d="M178.505 3.09873C178.505 1.87146 179.518 0.889648 180.684 0.889648C181.819 0.889648 182.709 1.77942 182.709 2.91463C182.709 4.1419 181.696 5.12371 180.53 5.12371C179.365 5.09303 178.505 4.20326 178.505 3.09873ZM177.156 10.8918L174.793 9.54185V9.38845L180.776 7.88505H180.929L177.248 22.4588C177.063 23.1031 176.971 23.6861 176.971 23.9622C176.971 24.3917 177.125 24.5758 177.493 24.5758C178.229 24.5758 179.641 23.3486 180.96 21.477L181.175 21.6304C179.549 24.5451 177.493 26.6315 175.56 26.6315C174.486 26.6315 173.873 25.9258 173.873 24.7906C173.873 24.2383 173.965 23.5633 174.21 22.6122L177.156 10.8918Z"
                  fill="#D1BEA6"/>
              <path
                  d="M197.068 11.1991C197.006 9.81841 196.515 8.43774 194.828 8.43774C190.655 8.43774 186.667 15.065 186.667 21.1706C186.667 23.5024 187.28 24.5456 188.569 24.5456C191.453 24.5149 195.687 19.1763 197.068 11.1991ZM195.656 26.6319C194.582 26.6319 193.969 25.9262 193.969 24.791C193.969 24.2388 194.061 23.5638 194.306 22.6126L195.472 17.8263C193.294 22.7967 189.888 26.6319 187.25 26.6319C185.102 26.6319 183.629 24.699 183.629 21.4774C183.629 14.482 189.489 7.91616 194.797 7.91616C195.963 7.91616 197.037 8.37637 197.681 8.89796L199.031 3.40596L196.362 2.14801V1.99461L202.59 0.491211H202.744L197.344 22.4592C197.16 23.1035 197.068 23.6865 197.068 23.9626C197.068 24.3922 197.221 24.5762 197.589 24.5762C198.326 24.5762 199.737 23.349 201.056 21.4774L201.271 21.6308C199.645 24.5456 197.589 26.6319 195.656 26.6319Z"
                  fill="#D1BEA6"/>
              <path
                  d="M207.193 17.6114C212.5 16.3841 215.2 14.2978 215.2 10.6774C215.2 9.51146 214.74 8.46828 213.39 8.46828C210.506 8.46828 207.837 13.1012 207.193 17.6114ZM209.432 26.6318C205.965 26.6318 204.002 24.1159 204.002 20.4034C204.002 14.6966 208.788 7.91602 213.973 7.91602C216.704 7.91602 217.931 9.45009 217.931 11.291C217.931 15.4023 212.286 17.0898 207.101 18.0103C207.039 18.5932 207.008 19.1455 207.008 19.7591C207.008 23.0113 208.358 24.8522 210.69 24.8522C212.746 24.8522 214.495 23.5943 216.06 21.3852L216.305 21.5386C214.71 24.5147 212.255 26.6318 209.432 26.6318Z"
                  fill="#D1BEA6"/>
              <path
                  d="M224.282 18.1324C226 13.9597 230.234 7.91545 233.855 7.91545C235.542 7.91545 236.064 8.8359 236.064 10.37C236.064 10.8302 235.972 11.4438 235.849 11.9041L233.026 22.4892C232.842 23.1335 232.75 23.7165 232.75 23.9926C232.75 24.4221 232.904 24.6062 233.272 24.6062C234.008 24.6062 235.42 23.379 236.739 21.5074L236.954 21.6608C235.328 24.5755 233.272 26.6619 231.339 26.6619C230.265 26.6619 229.651 25.9562 229.651 24.821C229.651 24.2687 229.743 23.5937 229.989 22.6426L232.873 11.7813C232.965 11.3211 233.057 10.9222 233.057 10.6461C233.057 10.1552 232.873 9.90975 232.443 9.90975C230.296 9.90975 225.141 14.9415 223.577 21.2926L222.319 26.3244H219.312L223.147 10.9222L220.784 9.57225V9.38816L226.767 7.88477H226.921L224.282 18.1324Z"
                  fill="#D1BEA6"/>
              <path
                  d="M241.801 19.7284C241.801 22.9807 243.029 24.8216 245.299 24.8216C247.324 24.8216 249.012 23.4409 250.576 21.2011L250.791 21.3545C249.349 24.3613 246.895 26.6318 244.133 26.6318C240.881 26.6318 238.795 24.1159 238.795 20.4034C238.795 14.5125 243.642 7.91602 248.889 7.91602C251.19 7.91602 252.54 8.95919 252.54 10.6467C252.54 11.9967 251.282 12.8864 250.116 13.1012H250.055C250.208 10.8614 249.993 8.591 247.968 8.591C244.624 8.591 241.801 14.4205 241.801 19.7284Z"
                  fill="#D1BEA6"/>
              <path
                  d="M256.283 17.6114C261.591 16.3841 264.291 14.2978 264.291 10.6774C264.291 9.51146 263.831 8.46828 262.481 8.46828C259.627 8.46828 256.958 13.1012 256.283 17.6114ZM258.523 26.6318C255.056 26.6318 253.092 24.1159 253.092 20.4034C253.092 14.6966 257.879 7.91602 263.064 7.91602C265.794 7.91602 267.022 9.45009 267.022 11.291C267.022 15.4023 261.376 17.0898 256.191 18.0103C256.13 18.5932 256.099 19.1455 256.099 19.7591C256.099 23.0113 257.449 24.8522 259.781 24.8522C261.837 24.8522 263.585 23.5943 265.15 21.3852L265.396 21.5386C263.8 24.5147 261.346 26.6318 258.523 26.6318Z"
                  fill="#D1BEA6"/>
              <path
                  d="M276.318 22.6125C276.318 19.2989 269.998 17.1819 269.998 13.2853C269.998 10.9535 272.575 7.91602 276.073 7.91602C278.466 7.91602 280 9.17395 280 10.8614C280 11.8432 279.08 12.5489 278.282 12.8864H278.22C277.944 10.6774 276.38 8.86714 274.416 8.86714C272.851 8.86714 271.961 10.033 271.961 11.3217C271.961 14.3285 278.343 16.875 278.343 20.5568C278.343 23.5329 274.937 26.6625 271.593 26.6625C268.863 26.6625 267.083 24.8522 267.083 23.0113C267.083 21.9989 267.85 20.9864 268.801 20.6182H268.863C269.231 23.2875 271.164 25.5886 273.342 25.5886C274.937 25.5579 276.318 24.3 276.318 22.6125Z"
                  fill="#D1BEA6"/>
            </svg>

            <img src="@/assets/img/a.svg" alt="Atrium">
          </div>
        </div>

      </div>
      <div class="footer__bottom">
        <span>All rights reserved — {{ (new Date()).getFullYear() }} © Atrium Group AG</span>
        <span>Website by <a href="https://betterandmore.eu" target="_blank" class="author">betterandmore</a></span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    mainMenu() {
      return this.$store.getters.getMainMenu
    },
    locale() {
      return this.$store.getters.getLocale
    },
    socialLinks() {
      return this.$store.getters.getSocials
    },
    menuLocale() {
      return (this.locale != this.$store.getters.getOptions.default_language) ? this.locale + '/' : ''
    },
  },
  mounted() {
    let video = document.querySelector(".hero__video");
    if (video === null || !(video.dataset.video == true && video.src)) {
      document.dispatchEvent(new Event('hero-video'))
    }
  },
  methods: {
    navigateTo(url) {
      const publicPath = '/' + this.$store.getters.getPublicPath
      const locale = this.$store.getters.getLocale
      if (url != '#') {
        this.$router.push(url)
        this.active = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';

.footer {
  background-color: $black_brown;
  padding: 72px 0 30px 0;

  &__social {
    position: relative;
  }
  &__logo img {
    margin: 0 auto;
    max-width: 280px;
    margin-bottom: 60px;
    display: block;
  }

  &__grid {
    width: 100%;
    padding: 0 50px;
    display: grid;
    grid-template-columns: repeat(2, 2fr) 5fr;
    @include tabletSize {
      grid-template-columns: none;
      grid-template-rows: repeat(2, 1fr);
    }
  }

  &__big-logo {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;

    svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-15%, -50%);
    }

    @include tabletSize {
      display: none;
    }
  }

  &__navigate, &__help, &__social {
    ul {
      list-style: none;
      @include tabletSize {
        text-align: center;
      }

      li {
        margin-bottom: 17px;
        color: $text_color;
        text-decoration: none;
        font-size: 1.7rem;
        transition: color .6s;
        @include tabletSize {
          font-size: 1.3rem;
        }

        &:hover {
          color: $gold_sand;
        }

        &:first-child {
          text-transform: uppercase;
          margin-bottom: 22px;
          font-size: 1.4rem;
          color: $beige;

          &:hover {
            color: $beige;
          }

          @include tabletSize {
            font-size: 1.3rem;
            margin-bottom: 10px;
          }
        }

        @include tabletSize {
          margin-bottom: 10px;
        }

        a {
          color: $text_color;
          text-decoration: none;
          font-size: 1.7rem;
          transition: color .6s;
          @include tabletSize {
            font-size: 1.3rem;
          }

          &:hover {
            color: $gold_sand;
          }
        }
      }
    }
  }

  &__navigate {
    @include tabletSize {
      display: none;
    }
  }

  &__help {
    @include tabletSize {
      margin-bottom: 30px;
    }
  }

  &__social {
    @include tabletSize {
      margin-bottom: 0px;
    }
  }

  &__bottom {
    color: $text_color;
    font-size: 1.7rem;
    display: flex;
    justify-content: space-between;
    padding: 70px 50px 0 50px;

    span a {
      text-decoration: none;
      color: $text_color;
    }

    span a.author{
      text-decoration:underline;
      @include tabletSize{
         font-size:13px;
      }
    }

    @include tabletSize {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin: 0 auto;
      text-align: center;
      border-top: 1px solid #66625F;
      font-size: 1.4rem;
      padding: 20px 50px 0 50px;

    }
  }
}
</style>
